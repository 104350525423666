// NewsCard
import React from 'react'
import {Link} from "react-router-dom";

const NewsCard = (props) => {
    const {
        index,
        isMobile,
        background,
        width,
        imageHeight,
        image,
        title,
        description,
        newsLink,
        externalLink,
        link
    } = props;

    return (
        <>
            <div
                key={index}
                className={`d-flex flex-column gap-1 rounded ${background}`}
                style={{height: "fit-content", minWidth: width}}
            >
                {
                    externalLink ?
                        <>
                            <a href={link} target={"_blank"} className={"text-decoration-none"} rel={"noreferrer"}>
                                <img
                                    src={image}
                                    className="img-fluid rounded"
                                    alt="news"
                                    height={imageHeight}
                                    width={"100%"}
                                    onError={(e) => {
                                        e.target.src = require("../../../Assets/Images/Dummy/news-image.png");
                                    }}
                                />
                            </a>
                            <div className={"px-3 py-2 d-flex flex-column"}>
                                <div
                                    className={`gilroy fw-400 text-blue truncate-multi-line ${isMobile ? "font-18" : "font-24"}`}
                                    style={{minHeight: isMobile ? "48px" : "64px"}}
                                >{title}</div>
                                <div
                                    className={`mt-1 gilroy-light fw-400 text-blue truncate-multi-line ${isMobile ? "font-14" : "font-16"}`}>{description}</div>
                            </div>
                        </>
                        :
                        <>
                            <Link to={`/news/${newsLink}`} className={"text-decoration-none"}>
                                <img
                                    src={image}
                                    className="img-fluid rounded"
                                    alt="news"
                                    height={imageHeight}
                                    width={"100%"}
                                    onError={(e) => {
                                        e.target.src = require("../../../Assets/Images/Dummy/news-image.png");
                                    }}
                                />
                            </Link>
                            <div className={"px-3 py-2 d-flex flex-column"}>
                                <div
                                    className={`gilroy fw-400 text-blue truncate-multi-line ${isMobile ? "font-18" : "font-24"}`}
                                    style={{minHeight: isMobile ? "48px" : "64px"}}
                                >{title}</div>
                                <div
                                    className={`mt-1 gilroy-light fw-400 text-blue truncate-multi-line ${isMobile ? "font-14" : "font-16"}`}>{description}</div>
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default NewsCard