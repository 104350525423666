// NewsSection
import React from "react";

import NewsCard from "../ReUseAbleComponents/CardsComponents/NewsCard";
import {newsData} from "../../Configs/NewsData";

const NewsSection = (props) => {
    const {
        isMobile,
    } = props;

    // const renderLearnMoreButton = () => {
    //     return (
    //         <>
    //             <div role={"button"} className={"border-blue px-3 py-2 rounded-5"}>
    //                 <div className={"gilroy fw-400 font-14 text-blue"}>View more from Pure</div>
    //             </div>
    //         </>
    //     )
    // }
    const renderMobile = () => {
        return (
            <>
                <section className={`bg-white py-4`}
                    // style={{height: "812px"}}
                >
                    <div className={"d-flex flex-column align-items-center justify-content-center "}>
                        <div className={"gilroy fw-400 font-28 text-blue"}>Latest news</div>
                        <div className={"w-75 text-center gilroy-light fw-400 font-14 text-blue my-2"}>Here’s the latest updates in the world of Pure Sport Group</div>
                    </div>
                    <div className={"my-3 px-3 py-2 w-100 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                        {
                            newsData?.map((news, index) => {
                                return (
                                    <NewsCard
                                        key={index}
                                        isMobile={isMobile}
                                        background={"bg-white"}
                                        index={index}
                                        width={"90%"}
                                        imageHeight={213}
                                        image={news?.image}
                                        title={news?.title}
                                        description={news?.description}
                                        newsLink={news?.id}
                                        externalLink={news?.externalLink}
                                        link={news?.link}
                                    />
                                )
                            })
                        }
                    </div>
                    {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                    {/*    {renderLearnMoreButton()}*/}
                    {/*</div>*/}
                </section>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <section className={`bg-white`}
                         // style={{height: "694px"}}
                >
                    <div className={"container py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-blue"}>Latest news</div>
                            <div className={"gilroy-light fw-400 font-18 text-blue my-2"}>Here’s the latest updates in the world of Pure Sport Group</div>
                        </div>
                        <div className={"row mt-3 p-0 py-3"}>
                            {
                                newsData?.map((news, index) => {
                                    return (
                                        <div key={index}
                                             className={"col-4 mb-4"}>
                                            <NewsCard
                                                key={index}
                                                isMobile={isMobile}
                                                background={"bg-white"}
                                                index={index}
                                                width={"100%"}
                                                imageHeight={213}
                                                image={news?.image}
                                                title={news?.title}
                                                description={news?.description}
                                                newsLink={news?.id}
                                                externalLink={news?.externalLink}
                                                link={news?.link}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                        {/*    {renderLearnMoreButton()}*/}
                        {/*</div>*/}
                    </div>
                </section>
            </>
        );
    };


    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default NewsSection