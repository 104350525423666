// SectionThree
import React from "react";
import sectionThreeImage from "../../Assets/Images/Sections/esports_image.png";
import esportsImageMobile from "../../Assets/Images/Sections/esports-image-mobile.png";
import IconTournament from "../../Assets/Images/Icons/icon-tournament.svg";
import IconEducation from "../../Assets/Images/Icons/icon-education.svg";
import IconsNews from "../../Assets/Images/Icons/icon-news.svg";
import {Link} from "react-router-dom";

const SectionThree = (props) => {
    const {
        isMobile,
    } = props;

    const renderIconCard = (icon, heading, description) => {
        return (
            <>
                <div className={"d-flex flex-column gap-3 align-items-start "}>
                    <img
                        src={icon}
                        alt={"icon"}
                        className={"img-fluid"}
                        height={54}
                        width={54}
                        loading={"lazy"}
                    />
                    <div className={"gilroy fw-400 font-21 text-white"}>{heading}</div>
                    <div className={"gilroy-light fw-400 font-17 text-white opacity-50"}>{description}</div>
                </div>
            </>
        )
    }
    const renderLearnMoreButton = () => {
        return (
            <>
                <div role={"button"} className={"border-white px-3 py-2 rounded-5"}>
                    <Link to={"/e-sports"} className={"gilroy fw-400 font-14 text-white text-decoration-none"}>Learn
                        more</Link>
                </div>
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                <section className={`bg-blue py-4`}
                    // style={{height: "812px"}}
                >
                    <div className={"d-flex flex-column align-items-center container"}>
                        <div className={"gilroy fw-400 font-28 text-white mb-2"}>Esports</div>
                        <img
                            src={esportsImageMobile}
                            alt={"section-image"}
                            className={"img-fluid"}
                            loading={"lazy"}
                        />
                        <div
                            className={"d-flex flex-column align-items-center align-items-center gap-3 mt-2"}>

                            <div className={"gilroy-light fw-400 font-14 text-white text-center"}>
                                Pure is leading the way with its one-stop-shop platform, aggregating global content for
                                both pro esports and amateur gaming.
                                <br/>
                                <br/>
                                Underpinned by competitive tournaments and leagues along with professional educational
                                content for academy courses, masterclasses and 1-2-1 coaching.
                            </div>
                            <div>
                                {renderLearnMoreButton()}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <section className={`bg-blue section-home-container`}
                    // style={{height: "694px"}}
                >
                    <div className={"h-100 d-flex align-items-center justify-content-center"}>
                        <div className={`row h-100 p-0 m-0`}>
                            <div className={"col-6 h-100 d-flex align-items-center justify-content-center"}>
                                <img
                                    src={sectionThreeImage}
                                    alt={"section-image"}
                                    className={"esport-section-home-image"}
                                    loading={"lazy"}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div
                                    className={"d-flex flex-column align-items-start justify-content-center px-4 h-100 w-75"}>
                                    <div className={"gilroy fw-400 font-50 text-white"}>Esports</div>
                                    <div className={"w-100 gilroy-light fw-200 font-18 text-white opacity-50 my-2"}>
                                        Pure is leading the way with its one-stop-shop platform, aggregating global
                                        content for both pro esports and amateur gaming.
                                        <br/>
                                        <br/>
                                        Underpinned by competitive tournaments and leagues along with professional
                                        educational content for academy courses, masterclasses and 1-2-1 coaching.
                                    </div>
                                    <div className={"d-flex flex-column mt-3 gap-4 w-100"}>
                                        <div className={"row col-12"}>
                                            <div className={"col-4"}>
                                                {renderIconCard(IconTournament, "Tournaments", "Global pro & amateur tournaments")}
                                            </div>
                                            <div className={"col-4"}>
                                                {renderIconCard(IconEducation, "Education", "Academy, 1-2-1 and masterclasses from verified professionals")}
                                            </div>
                                            <div className={"col-4"}>
                                                {renderIconCard(IconsNews, "News", "Top stories, team, game and player news.")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-1"}>
                                        {renderLearnMoreButton()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </>
        );
    };


    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default SectionThree