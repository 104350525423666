// Sports
import React from "react";
import SportsHeroSection from "../../Components/SportsComponents/SportsHeroSection";
import ImageMultiSports from "../../Assets/Images/Sports/multi-sports.png";
import NewsCard from "../../Components/ReUseAbleComponents/CardsComponents/NewsCard";
import SportBrands from "../../Components/SportsComponents/SportBrands";
import IconWorldWide from "../../Assets/Images/Icons/icon-world-wide.png";

import BgNoLife from "../../Assets/Images/Sports/bg-nolife.png";
import BgNoLifeMobile from "../../Assets/Images/Sports/bg-nolife-mobile.png";
import EportsImageMobile from "../../Assets/Images/Sports/multi-sports-mobile.png";
import {newsData} from "../../Configs/NewsData";

const Sports = (props) => {
    const {
        isMobile,
    } = props;

    const renderCard = (count, countHeading, isWordWideIcon) => {
        return (
            <>
                <div
                    className={`d-flex flex-column align-items-start px-3 py-2 rounded-4 bg-blue ${isMobile ? "count-card-mobile" : "count-card"}`}>
                    {
                        isWordWideIcon ?
                            <>
                                <img src={IconWorldWide} alt={"icon"} className={`mb-2`} height={59}/>
                                <div
                                    className={`gilroy-bold fw-400 text-white border-bottom-skyblue-4 w-25 ${isMobile ? "font-32" : "font-50"}`}></div>
                            </> :

                            <div
                                className={`gilroy-bold fw-400 text-white border-bottom-skyblue-4 w-25 ${isMobile ? "font-32" : "font-50"}`}
                                style={{lineHeight: "72px"}}>{count}</div>
                    }
                    <div className={`gilroy-bold fw-400 text-white pt-2 ${isMobile ? "font-12" : "font-18"}`}
                         style={{lineHeight: isMobile ? "14px" : "20px"}}>{countHeading}</div>
                </div>
            </>
        )
    }

    // const renderLearnMoreButton = () => {
    //     return (
    //         <>
    //             <div role={"button"} className={"border-blue px-3 py-2 rounded-5"}>
    //                 <div className={"gilroy fw-400 font-14 text-blue"}>View more from Pure</div>
    //             </div>
    //         </>
    //     )
    // }
    const renderMobile = () => {
        return (
            <>
                <SportsHeroSection isMobile={isMobile}/>
                <section className={`bg-white py-5`}
                >
                    <div className={"d-flex flex-column align-items-center container"}>
                        <div className={"gilroy-bold fw-400 font-28 text-blue mb-2"}>Multi-sport integration</div>
                        <div className={"gilroy-light text-center fw-400 font-14 text-blue mb-2"}>
                            Football, Cricket, NFL, NBA & MLB and more
                        </div>
                        <img
                            src={EportsImageMobile}
                            alt={"section-image"}
                            className={"img-fluid"}
                        />
                        <div className={"gilroy-light text-center fw-400 font-14 text-blue"}>
                            Beyond esports, Pure develops apps & websites for the biggest sports globally.
                            <br/>
                            <br/>
                            Our innovative technology integrates all your favourite sports into one cohesive digital ecosystem with hyper-personalisation.
                            <br/>
                            <br/>
                            Sports fans will get the most relevant content, news and updates tailored to their unique preferences.
                        </div>
                    </div>
                </section>
                <section className={`bg-blue `}>
                    <div className={"pt-5"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`gilroy-bold fw-400 font-28 text-white`}>
                                Our Brands
                            </div>
                        </div>
                        <div className={"mt-5"}>
                            <SportBrands isMobile={isMobile}/>
                        </div>
                    </div>
                </section>
                <section className={`bg-white`}>
                    <div className={"pt-5 container"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`gilroy-bold fw-400 font-28 text-blue text-center`}>
                                The average sports fan follows 6.2 sports
                            </div>
                        </div>
                        <div className={"py-5"}>
                            <div className={"row w-100 p-0 m-0 "}>
                                <div className={"col-6 mb-4"}>
                                    {renderCard("$485BN", "Gamers worldwide")}
                                </div>
                                <div className={"col-6 mb-4"}>
                                    {renderCard("3.5BN", "Football fans")}
                                </div>
                                <div className={"col-6 mb-4"}>
                                    {renderCard("2.5BN", "Cricket fans")}
                                </div>
                                <div className={"col-6 mb-4"}>
                                    {renderCard("2BN", "US sport fans")}
                                </div>
                                <div className={"col-6 mb-4"}>
                                    {renderCard("1.5BN", "Televised event Qatar World Cup 2022")}
                                </div>
                                <div className={"col-6 mb-4"}>
                                    {renderCard("200+", "Sports with international recognition")}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    className={"position-relative bg-blue"}
                    style={{backgroundImage: `url(${BgNoLifeMobile})`, height: "441px"}}
                >
                    <div className={"h-100 d-flex align-items-center justify-content-center"}>
                        <div className={"gilroy-bold fw-400 font-28 text-white text-center"}>
                           The global sports market reached a value of nearly $484.9 billion in 2023, having grown at a compound annual growth rate (CAGR) of 3.6% since 2018
                        </div>
                    </div>
                </section>
                <section className={`bg-white py-4`}
                    // style={{height: "812px"}}
                >
                    <div className={"d-flex flex-column align-items-center justify-content-center "}>
                        <div className={"gilroy fw-400 font-28 text-blue"}>Latest news</div>
                        <div className={"w-75 text-center gilroy-light fw-400 font-14 text-blue opacity my-2"}>Here’s the latest updates in the world of Pure Sport Group</div>
                    </div>
                    <div className={"my-3 px-3 py-2 w-100 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                        {
                            newsData?.map((news, index) => {
                                return (
                                    <NewsCard
                                        key={index}
                                        isMobile={isMobile}
                                        background={"bg-white"}
                                        index={index}
                                        width={"90%"}
                                        imageHeight={213}
                                        image={news?.image}
                                        title={news?.title}
                                        description={news?.description}
                                        newsLink={news?.id}
                                        externalLink={news?.externalLink}
                                        link={news?.link}
                                    />
                                )
                            })
                        }
                    </div>
                    {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                    {/*    {renderLearnMoreButton()}*/}
                    {/*</div>*/}
                </section>
            </>
        )
    };


    const renderLaptop = () => {
        return (
            <>
                <SportsHeroSection isMobile={isMobile}/>
                <section className={`bg-white blue-section-container`}>
                    <div className={"container-xxl h-100 d-flex align-items-center justify-content-center"}>
                        <div className={`row h-100`}>
                            <div className={`m-auto col-6`}>
                                <div className={"d-flex flex-column gap-5"}>
                                    <div className={"d-flex flex-column"}>
                                        <div className={`gilroy-bold fw-400 font-50 text-blue`}>
                                            Multi-sport integration
                                        </div>
                                        <div className={`gilroy-bold fw-400 font-22 text-skyblue`}>
                                            Football, Cricket, NFL, NBA & MLB and more
                                        </div>
                                    </div>
                                    <div className={`gilroy-medium fw-400 font-22 text-blue`}
                                         style={{lineHeight: "27.18px"}}
                                    >
                                        <ul>
                                            <li>Beyond esports, Pure develops apps & websites for the biggest sports globally.
                                            </li>
                                            <br/>
                                            <li>Our innovative technology integrates all your favourite sports into one cohesive digital ecosystem with hyper-personalisation.
                                            </li>
                                            <br/>
                                            <li>
                                                Sports fans will get the most relevant content, news and updates tailored to their unique preferences.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={`d-flex col-6 justify-content-end m-auto`}>
                                <div className={""}>
                                    <img
                                        src={ImageMultiSports}
                                        className={"img-fluid"}
                                        alt={"section"}
                                        // height={499}
                                        // width={374}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`bg-blue `}>
                    <div className={"pt-5"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`gilroy-bold fw-400 font-50 text-white`}>
                                Our Brands
                            </div>
                        </div>
                        <div className={"mt-5"}>
                            <SportBrands isMobile={isMobile}/>
                        </div>
                    </div>
                </section>
                <section className={`bg-white`}>
                    <div className={"pt-5 container"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`gilroy-bold fw-400 font-50 text-blue text-center`}>
                                The average sports fan follows 6.2 sports
                            </div>
                        </div>
                        <div className={"py-5 w-100 d-flex align-items-center justify-content-center"}>
                            <div className={"row w-75 p-0 m-0"}>
                                <div className={"col-6 col-xxl-4 mb-5"}>
                                    <div style={{width:"90%"}}>
                                    {renderCard("$485BN", "Gamers worldwide")}

                                    </div>
                                </div>
                                <div className={"col-6 col-xxl-4 mb-5"}>
                                    <div style={{width:"90%"}}>
                                    {renderCard("3.5BN", "Football fans")}
                                    </div>
                                </div>
                                <div className={"col-6 col-xxl-4 mb-5 pe-0"}>
                                    <div style={{width:"90%"}}>
                                    {renderCard("2.5BN", "Cricket fans")}
                                    </div>
                                </div>
                                <div className={"col-6 col-xxl-4 mb-5"}>
                                    <div style={{width:"90%"}}>
                                    {renderCard("2BN", "US sport fans")}
                                    </div>
                                </div>
                                <div className={"col-6 col-xxl-4 mb-5"}>
                                    <div style={{width:"90%"}}>
                                    {renderCard("1.5BN", "Televised event Qatar World Cup 2022")}
                                    </div>
                                </div>
                                <div className={"col-6 col-xxl-4 mb-5 pe-0"}>
                                    <div style={{width:"90%"}}>
                                    {renderCard("200+", "Sports with international recognition")}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
                <section
                    className={"position-relative bg-blue"}
                    style={{backgroundImage: `url(${BgNoLife})`, height: "562px"}}
                >
                    <div className={"h-100 d-flex align-items-center justify-content-center"}>
                        <div className={"gilroy-bold fw-400 font-50 text-white text-center w-75"}>
                           The global sports market reached a value of nearly $484.9 billion in 2023, having grown at a compound annual growth rate (CAGR) of 3.6% since 2018
                        </div>
                    </div>
                </section>
                <section className={`bg-white`}
                    // style={{height: "694px"}}
                >
                    <div className={"container py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-blue"}>Latest news</div>
                            <div className={"gilroy-light fw-400 font-18 text-blue opacity my-2"}>
                                Here’s the latest updates in the world of Pure Sport Group
                            </div>
                        </div>
                        <div className={"row mt-3 p-0 py-3"}>
                            {
                                newsData?.map((news, index) => {
                                    return (
                                        <div key={index}
                                             className={"col-4 mb-4"}>
                                            <NewsCard
                                                key={index}
                                                isMobile={isMobile}
                                                background={"bg-white"}
                                                index={index}
                                                width={"100%"}
                                                imageHeight={213}
                                                image={news?.image}
                                                title={news?.title}
                                                description={news?.description}
                                                newsLink={news?.id}
                                                externalLink={news?.externalLink}
                                                link={news?.link}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                        {/*    {renderLearnMoreButton()}*/}
                        {/*</div>*/}
                    </div>
                </section>
            </>
        )
    };

    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default Sports