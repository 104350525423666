// SectionOne
import React from "react";

import {Card} from "react-bootstrap";
// import {Link} from "react-router-dom";

const SectionOne = (props) => {
    const {
        isMobile,
    } = props;

    const renderMain = () => {
        return (
            <>
                <section className="d-flex justify-content-center align-items-center">
                    <Card className={`border-0 rounded-0 position-relative w-100 ${isMobile ? "" : "video-container"}`}
                          style={{height: isMobile ? "554px" : "", marginTop:"-110px"}}
                    >

                        <video
                            className={`bg-blue position-absolute hero-section-video top-0 start-0 ${isMobile ?"h-100 w-100":"h-100 w-100"}`}
                            style={{
                                backgroundRepeat: "no-repeat",
                                // objectFit: "fill",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                // width: isMobile && "100%",
                                // height: isMobile && "100%",
                            }}
                            autoPlay={true}
                            loop
                            muted
                            playsInline={true}
                            controls={false}
                        >
                            <source src={"https://cdn.fanzine.com/pure-sports-group/hero-section-video.mp4"}
                                    type="video/mp4"/>
                            {/*Your browser does not support the video tag.*/}
                        </video>
                        <Card.Body
                            className="hero-section-text d-flex align-items-center justify-content-center position-relative"
                        >
                            <div
                                className={`d-flex align-items-center justify-content-evenly flex-column text-center ${isMobile ? "" : "h-75 mt-4 container"}`}>
                                {/*<div></div>*/}
                                <div className={"d-flex flex-column align-items-center justify-content-center gap-3"}>
                                    <div
                                        className={`gilroy-bold fw-400 text-white ${isMobile ? "font-32" : "font-58"}`}
                                        style={{lineHeight: isMobile ? "45px" : "80px"}}
                                    >Where sports meets esports
                                    </div>
                                    <div
                                        className={`gilroy-light fw-400 text-white ${isMobile ? "font-14" : "font-30"}`}>
                                        Pure Sport Group is bridging the gap between esports & traditional sports to
                                        create a whole ecosystem all in one place, focusing on competitive gaming,
                                        education and news content.
                                    </div>
                                </div>
                                {/*<Link to={"/"}*/}
                                {/*      className={`text-decoration-none bg-skyblue-nav rounded-5 px-4 py-2 mt-5 ${isMobile && "w-100"}`}>*/}
                                {/*    <div className={"text-blue gilroy-bold fw-400 font-14"}>*/}
                                {/*        About Pure*/}
                                {/*    </div>*/}
                                {/*</Link>*/}
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </>
        )
    };
    return renderMain();
};

export default SectionOne