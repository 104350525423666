// NewsDetailPage
import React from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment';

import {newsData} from "../../Configs/NewsData";
import customizeHtmlContent from "../../CustomFunctions/customizeHtmlContent";
import NewsSection from "../../Components/HomeComponents/NewsSection";


const NewsDetailPage = (props) => {
    const {isMobile} = props;

    const {id} = useParams();
    const news = newsData.find(n => n.id === parseInt(id));

    if (!news) {
        return <div>News not found</div>;
    }

    const renderMobile = () => {
        return (
            <div style={{marginTop: "-10px"}}>
                <img className={"img-fluid h-100 w-100"} src={news?.backgroundImage} alt={news?.title}/>
                <section className={"bg-white py-4"}>
                    <div className={"container"}>
                        <div className={"gilroy-bold fw-400 font-28 text-blue"}
                             style={{lineHeight: "30px"}}>{news?.title}</div>
                        <div className={"py-4 d-flex flex-row align-items-center gap-2 justify-content-start"}>
                            <img src={news?.provider} alt={news?.title} className={"img-fluid"} height={30} width={30}/>
                            <div
                                className={"gilroy-medium fw-400 font-14 text-blue d-flex flex-row align-items-center gap-2"}>
                                <div>{news?.timeToRead}</div>
                                <div>|</div>
                                <div>{moment(news.time).format('DD MMMM YYYY')}</div>
                            </div>
                        </div>
                        <div className={"gilroy-light fw-400 font-14 text-blue"}
                             style={{lineHeight: "24px", letterSpacing: "0.1px"}}>
                            {news?.description}
                        </div>
                        <div className={"py-3 gilroy-light fw-400 font-14 text-blue"}>
                            {/*<div dangerouslySetInnerHTML={{__html: news.htmlContent}}/>*/}
                            <div dangerouslySetInnerHTML={customizeHtmlContent(news.htmlContent)}/>
                        </div>
                    </div>
                </section>
                <NewsSection
                    isMobile={isMobile}
                />
            </div>
        )
    }
    const renderLaptop = () => {
        return (
            <>
                <div className={"bg-privacy-policy-header bg-blue"}></div>
                <img className={"img-fluid h-100 w-100"} src={news?.backgroundImage} alt={news?.title}/>
                <section className={"bg-white py-4"}>
                    <div className={"container"}>
                        <div className={"gilroy-bold fw-400 font-50 text-blue"}
                             style={{lineHeight: "50px"}}>{news?.title}</div>
                        <div className={"py-4 d-flex flex-row align-items-center gap-3"}>
                            <img src={news?.provider} alt={news?.title} className={"img-fluid"}/>
                            <div
                                className={"gilroy-medium fw-400 font-18 text-blue d-flex flex-row align-items-center gap-2"}>
                                <div>{news?.timeToRead}</div>
                                <div>|</div>
                                <div>{moment(news.time).format('DD MMMM YYYY')}</div>
                            </div>
                        </div>
                        <div className={"gilroy-light fw-400 font-28 text-blue"}
                             style={{lineHeight: "40px", letterSpacing: "-0.1px"}}>
                            {news?.description}
                        </div>
                        <div className={"py-3"}>
                            {/*<div dangerouslySetInnerHTML={{__html: news.htmlContent}}/>*/}
                            <div dangerouslySetInnerHTML={customizeHtmlContent(news.htmlContent)}/>
                        </div>
                    </div>
                </section>
                <NewsSection
                    isMobile={isMobile}
                />
            </>
        )
    }
    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default NewsDetailPage;