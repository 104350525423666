// TermsOfUse
import React from "react";
import {Link} from "react-router-dom";

const TermsOfUse = (props) => {
    const {
        isMobile,
    } = props;


    const renderMain = () => {
        return (
            <>
                {
                    !isMobile &&
                    <div className={"bg-privacy-policy-header bg-blue"}></div>
                }
                <section className={`bg-white container py-5`}>
                    <div className={"d-flex flex-column align-items-center justify-content-center"}>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-28" : "font-60"}`}>
                            Pure Terms of Use
                        </div>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Updated July 26, 2024
                        </div>
                    </div>
                    <div className={"mt-4"}>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            By using Our Site you accept these Terms of Use. These Terms of Use, together with any other
                            documents referred to herein (unless otherwise stated), set out the terms of use governing
                            your use of this app/website, (“Our Site”). It is recommended that you print a copy of these
                            Terms of Use for your future reference.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            These Terms of Use were last updated on 26th July 2024.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Your agreement to comply with these Terms of Use is indicated by your use of Our Site. If
                            you do not agree to these Terms of Use, you must stop using Our Site immediately. You will
                            also be required to accept these Terms of Use if you sign up for an Account.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            The following documents may also apply to your use of Our Site:
                        </div>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            <br/>
                            <ul>
                                <li>Our Privacy Policy, available at
                                    <Link className={"ms-1 text-blue"}
                                          to={"/privacy-policy"}>www.puresportgroup.com/privacy</Link>. This is also
                                    referred to below in Parts 3, 7, and 16.
                                </li>
                                <br/>
                                <li>Our Cookie Policy, available at
                                    <Link className={"ms-1 text-blue"}
                                          // to={"/cookies"}>https://puresportgroup.com/cookies</Link>. This is also
                                          to={"/privacy-policy"}>https://puresportgroup.com/cookies</Link>. This is also
                                    referred to below in Part 16.
                                </li>
                                <br/>
                                <li>If you purchase goods from Us, Our Terms of Sale will apply to the sale. These terms
                                    are also referred to below in Parts 12 and 13.
                                </li>
                            </ul>
                        </div>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            1. Definitions and Interpretation
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            1.1 In these Terms of Use, unless the context otherwise requires, the following expressions
                            have the following meanings:
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;Account&quot;</div>
                            <div
                                className={`col-7 col-md-6 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means an account required to access certain features on Our Site, as set out in Part 7;
                            </div>
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;Contact
                                Tools&quot;</div>
                            <div
                                className={`col-7 col-md-6 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means any online communications facility that We make available on Our Site enabling you
                                to contact Us including, but not limited to, contact forms and live chat;
                            </div>
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;Content&quot;</div>
                            <div
                                className={`col-7 col-md-6 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means any and all text, images, audio, video, scripts, code, software, databases, and
                                any other form of information capable of being stored on a computer that appears on, or
                                forms part of, Our Site;
                            </div>
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;User&quot;</div>
                            <div
                                className={`col-7 col-md-6 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means a user of Our Site;
                            </div>
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;User
                                Content&quot;</div>
                            <div
                                className={`col-7 col-md-6 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means all content shared by Users on Our Site; and
                            </div>
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;We/Us/Our&quot;</div>
                            <div
                                className={`col-7 col-md-6 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means Pure Sports Group Limited.
                            </div>
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            2. Information About Us
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            2.1 Our Site is operated by Pure Sport Group Ltd. We are a limited company registered in
                            England and Wales under company number 15792048. Our address is 119c Eastbank Street,
                            Southport, England, PR8 1DQ.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            3. How to Contact Us
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            2.1 Our Site is operated by Pure Sport Group Ltd. We are a limited company registered in
                            England and Wales under company number 15792048. Our address is 119c Eastbank Street,
                            Southport, England, PR8 1DQ.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            3.1 To contact Us by email, please email Us at <a target={"_blank"} rel={"noreferrer"}
                                                                              className={"text-decoration-none text-blue ms-1"}
                                                                              href="mailto:support@puresportgroup.com">support@puresportgroup.com</a>.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            3.2 When using contacting Us by any other means, the following rules apply, and you must not
                            communicate, submit, or otherwise do anything that:
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            a) is sexually explicit;
                            <br/>
                            <br/>
                            b) in any way sexualises minors (including, but not limited to, child sexual abuse
                            material);
                            <br/>
                            <br/>
                            c) is obscene, deliberately offensive, hateful, or otherwise inflammatory;
                            <br/>
                            <br/>
                            d) promotes violence;
                            <br/>
                            <br/>
                            e) promotes, encourages, incites, or supports acts of terrorism;
                            <br/>
                            <br/>
                            f) promotes or assists in any form of unlawful activity;
                            <br/>
                            <br/>
                            g) is defamatory of another person;
                            <br/>
                            <br/>
                            h) bullies, insults, intimidates, or humiliates another person;
                            <br/>
                            <br/>
                            i) discriminates against, or is in any way defamatory of, any person, group, or class of
                            persons; race; nationality; gender; gender identity; sexual orientation; religious or
                            philosophical beliefs; disability; or age;
                            <br/>
                            <br/>
                            j) is intended or otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset,
                            or embarrass another person;
                            <br/>
                            <br/>
                            k) is calculated or otherwise likely to deceive;
                            <br/>
                            <br/>
                            l) is intended or otherwise likely to infringe (or threaten to infringe) another person’s
                            right to privacy or otherwise uses their personal information in a way that you do not have
                            a right to;
                            <br/>
                            <br/>
                            m) misleadingly impersonates any person or otherwise misrepresents your identity or
                            affiliation in a way that is calculated to;
                            <br/>
                            <br/>
                            n) implies any form of affiliation with Us or any other party where there is none;
                            <br/>
                            <br/>
                            o) infringes, or assists in the infringement of, the intellectual property rights
                            (including, but not limited to, copyright, designs, patents, trade marks, and database
                            rights) belonging to Us or any other party;
                            <br/>
                            <br/>
                            p) is in breach of any legal duty owed to another party including, but not limited to,
                            contractual duties and duties of confidence.
                            <br/>
                            <br/>
                            3.3 We may monitor any and all communications made using Our Contact Tools.
                            <br/>
                            <br/>
                            3.4 Any personal information sent to Us, whether via Our Contact Tools or otherwise
                            (including, but not limited to, your name and contact details) will be collected, used, and
                            held in accordance with your rights and Our obligations under data protection law, as set
                            out in Our Privacy Policy, available from
                            <Link className={"ms-1 text-blue"}
                                  to={"/privacy-policy"}>www.puresportgroup.com/privacy</Link>..
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            4. Access to Our Site
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            4.1 Access to Our Site is free of charge.
                            <br/>
                            <br/>
                            4.2 It is your responsibility to make the arrangements necessary in order to access Our
                            Site.
                            <br/>
                            <br/>

                            4.3 Access to Our Site is provided on an “as is” and on an “as available” basis. We may
                            suspend or discontinue Our Site (or any part of it) at any time. We do not guarantee that
                            Our Site will always be available or that access to it will be uninterrupted.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            5. Changes to Our Site
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            We may alter and update Our Site (or any part of it) at any time. If We make any significant
                            alterations to Our Site (or any part of it), We will try to give you reasonable notice of
                            the alterations.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            6. Changes to these Terms of Use
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            6.1 We may alter these Terms of Use at any time. If We do so, details of the changes will be
                            highlighted at the top of this page. Any changes made to these Terms of Use will apply to
                            your use of Our Site the first time you use it after the changes have been implemented. You
                            are advised to check this page every time you use Our Site.
                            <br/>
                            <br/>
                            6.2 If any part of the current version of these Terms of Use conflicts with any previous
                            version(s), the current version shall prevail unless We explicitly state otherwise.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            7. Accounts
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            7.1 Certain features on Our Site, including the ability to purchase goods from Us, may
                            require an Account.
                            <br/>
                            <br/>
                            7.2 Only Users aged 18 or over may create an Account to use our Betting features.
                            <br/>
                            <br/>
                            7.3 When creating an Account, the information you provide must be accurate and complete. If
                            any of your information changes, it is your responsibility to ensure that your Account is
                            kept up to date.
                            <br/>
                            <br/>
                            7.4 We recommend that you choose a strong password for your Account, consisting of a
                            combination of lowercase and uppercase letters, numbers, and symbols.
                            <br/>
                            <br/>
                            7.5 It is your responsibility to keep your Account details safe. You must not share your
                            Account details with anyone else. If you believe your Account is being used by someone else
                            without your permission, please change your password immediately.
                            <br/>
                            <br/>
                            7.6 You must not use another person’s Account.
                            <br/>
                            <br/>
                            7.7 All personal information provided by you in your Account will be collected, used, and
                            held in accordance with your rights and Our obligations under data protection law, as set
                            out in Our Privacy Policy, available from
                            <Link className={"ms-1 text-blue"}
                                  to={"/privacy-policy"}>www.puresportgroup.com/privacy</Link>.
                            <br/>
                            <br/>
                            7.8 If you wish to delete your Account, you may do so at any time. If you delete your
                            Account, you will no longer have access to the features on Our Site requiring an Account.
                            Deleting your Account will result in the removal of your information from Our Site. For
                            further details about the retention and deletion of personal data, please refer to Our
                            Privacy Policy, available from <Link className={"ms-1 text-blue"}
                                                                 to={"/privacy-policy"}>www.puresportgroup.com/privacy</Link>..
                            <br/>
                            <br/>
                            7.9 If you delete your Account, any User Content that you have shared on Our Site will also
                            be deleted.
                            <br/>
                            <br/>
                            7.10 We may disable your Account if, in Our reasonable opinion, you have breached these
                            Terms of Use.
                        </div>
                        <br/>

                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            8. How You May Use Our Site and Content (Intellectual Property)
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            8.1 With the exception of User Content (please refer to Part 9), all Content included on Our
                            Site and the copyright and other intellectual property rights in that Content belongs to or
                            has been licensed by Us, unless specifically labelled otherwise. All Content (including User
                            Content) is protected by applicable United Kingdom and international intellectual property
                            laws and treaties.
                            <br/>
                            <br/>
                            8.2 Users retain the ownership of the copyright and all other intellectual property rights
                            in their User Content (with the exception of any part of any User Content which is owned by
                            a third party, in which case, Users must obtain express permission for such material to be
                            used in the User Content). For information on the use of User Content, please refer to Part
                            9.
                            <br/>
                            <br/>
                            8.3 You may access, view, and use Our Site as an app or in a web browser (including any web
                            browsing capability built into other types of software or app) and you may download Our Site
                            (or any part of it) for caching (this usually occurs automatically).
                            <br/>
                            <br/>
                            8.4 You may print one copy and download extracts of any page(s) from Our Site for personal
                            use only.
                            <br/>
                            <br/>
                            8.5 You may not otherwise modify the printed copies, downloaded extracts, or downloaded or
                            saved Content in any way. Images, video, audio, or any other Content downloaded from Our
                            Site must not be used separately from accompanying text.
                            <br/>
                            <br/>
                            8.6 You may not use any Content (including User Content) from Our Site for commercial
                            purposes without first obtaining a licence from Us, Our licensors, or the relevant User, as
                            applicable. This does not prevent the normal access, viewing, and use of Our Site for
                            general information purposes by business users or consumers.
                            <br/>
                            <br/>
                            8.7 Our status as the owner and author of the Content on Our Site (or that of identified
                            licensors or Users, as applicable) must always be acknowledged.
                            <br/>
                            <br/>
                            8.8 Nothing in these Terms of Use limits or excludes the provisions of Chapter III of the
                            Copyright, Designs and Patents Act 1988, ‘Acts Permitted in Relation to Copyright Works’,
                            which provides exceptions allowing certain uses of copyright material including (but not
                            limited to) non-commercial research and private study; text and data mining for
                            non-commercial research; criticism, review, and reporting current events; teaching;
                            accessibility; time-shifting; and parody, caricature, and pastiche. Further information is
                            available from the UK Intellectual Property Office.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            9. User Content
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            9.1 User Content on Our Site includes reviews, comments, etc..
                            <br/>
                            <br/>
                            9.2 An Account is required for the submission of User Content to Our Site.
                            <br/>
                            <br/>
                            9.3 All User Content and communications with other Users on Our Site must comply with our
                            content standards.
                            <br/>
                            <br/>
                            9.4 You warrant that you will comply with Part 9.3 and the content standards referred to.
                            You will be liable to Us and will, to the fullest extent permissible by law, indemnify Us
                            for any breach of your warranty. You will be responsible for any loss or damage suffered by
                            Us as a result of such a breach.
                            <br/>
                            <br/>
                            9.5 We are not responsible for any loss of User Content submitted to Our Site. It is your
                            sole responsibility to secure and backup your User Content.
                            <br/>
                            <br/>
                            9.6 All User Content is considered non-confidential and non-proprietary.
                            <br/>
                            <br/>
                            9.7 We may reject, reclassify, or remove any User Content from Our Site where, in Our sole
                            opinion, it violates our terms.
                            <br/>
                            <br/>
                            9.8 We have the right to disclose your identity to a third party if that third party claims
                            that any User Content submitted by you infringes their intellectual property rights
                            (including, but not limited to, copyright) or their right to privacy.
                            <br/>
                            <br/>
                            9.9 User Content is not approved or verified by Us before it is displayed on Our Site. The
                            opinions, views, and values expressed in User Content on Our Site are those of the relevant
                            Users and do not represent Our opinions, views, or values.
                            <br/>
                            <br/>
                            9.10 We do not store any terrorist content.
                            <br/>
                            <br/>
                            9.11 If you wish to make a complaint about any User Content, please contact Us using the
                            details provided above in Part 3.
                            <br/>
                            <br/>
                            9.12 If you wish to remove User Content, you may do so. Please note that caching or
                            references to your User Content may not be made unavailable immediately and may not be made
                            unavailable where they are outside of Our reasonable control.
                            <br/>
                            <br/>
                            9.13 User Content on Our Site and the copyright and other intellectual property rights in
                            that User Content, unless specifically labelled otherwise, belongs to or has been licensed
                            by the User identified along with that User Content. All User Content is protected by
                            applicable United Kingdom and international intellectual property laws and treaties.
                            <br/>
                            <br/>
                            9.14 You (or your licensors, as appropriate) retain the ownership of your User Content and
                            all associated intellectual property rights. When you submit User Content to Our Site, you
                            grant Us an unconditional, non-exclusive, fully transferrable, royalty free, perpetual,
                            worldwide licence to use, store, archive, syndicate, publish, transmit, adapt, edit,
                            reproduce, distribute, prepare derivative works from, display, perform, and sub-licence your
                            User Content to the extent necessary to operate and promote Our Site.
                            <br/>
                            <br/>
                            9.15 In addition to the licence granted to Us under Part 9.14, by submitting User Content to
                            Our Site, Users grant each other the right to copy and quote their User Content within Our
                            Site.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            10. Links to Our Site
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            10.1 You may link to any page on Our Site.
                            <br/>
                            <br/>
                            10.2 Links to Our Site must be fair and lawful. You must not take unfair advantage of Our
                            reputation or attempt to damage Our reputation.
                            <br/>
                            <br/>
                            10.3 You must not link to Our Site in a manner that suggests any association with Us (where
                            there is none) or any endorsement or approval from Us (where there is none).
                            <br/>
                            <br/>
                            10.4 Your link should not use any logos or trademarks displayed on Our Site without Our
                            express written permission.
                            <br/>
                            <br/>
                            10.5 You must not frame or embed Our Site on another website without Our express written
                            permission.
                            <br/>
                            <br/>
                            10.6 You may not link to Our Site from another website the main content of which is
                            unlawful; obscene; offensive; inappropriate; dishonest; defamatory; threatening; racist,
                            sexist, or otherwise discriminatory; that promotes violence, racial hatred, or terrorism;
                            that infringes intellectual property rights; or that We deem to be otherwise objectionable.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            11. Links to Other Sites
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            11.1 Links to other websites may be included on Our Site. Unless expressly stated, these
                            sites are not under Our control. We accept no responsibility or liability for the content of
                            third-party websites.
                            <br/>
                            <br/>
                            11.2 The inclusion of a link to another website on Our Site is for information purposes only
                            and does not imply any endorsement of that website or of its owners, operators, or any other
                            parties involved with it.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            12. Disclaimers
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            12.1 Nothing on Our Site constitutes professional advice on which you should rely. It is
                            provided for general information purposes only.
                            <br/>
                            <br/>
                            12.2 We make reasonable efforts to ensure that Our Content on Our Site is complete,
                            accurate, and up to date, but to the extent permitted by law, We make no warranties,
                            representations, or guarantees (express or implied) that this will always be the case.
                            Please note that this does not apply to information concerning goods for sale through Our
                            Site. Please refer to Our Terms of Sale for more information.
                            <br/>
                            <br/>
                            12.3 If you are a business user, We exclude all implied representations, warranties,
                            conditions, and other terms that may apply to Our Site and Content.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            13. Our Liability
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            13.1 The provisions of this Part 13 apply only to the use of Our Site and not to the sale of
                            goods. The sale of goods is governed by Our Terms of Sale.
                            <br/>
                            <br/>
                            13.2 Nothing in these Terms of Use excludes or restricts Our liability for fraud or
                            fraudulent misrepresentation, for death or personal injury resulting from negligence, or for
                            any other forms of liability which cannot be lawfully excluded or restricted.
                            <br/>
                            <br/>
                            13.3 If you are a business user (i.e. you are using Our Site in the course of business or
                            for commercial purposes), to the fullest extent permissible by law, We accept no liability
                            for any loss or damage, whether foreseeable or otherwise, in contract, tort (including
                            negligence), for breach of statutory duty, or otherwise, arising out of or in connection
                            with the use of (or inability to use) Our Site or the use of or reliance upon any Content
                            (including User Content) included on Our Site.
                            <br/>
                            <br/>
                            13.4 If you are a business user, We accept no liability for loss of profit, sales, business,
                            or revenue; loss of business opportunity, goodwill, or reputation; loss of anticipated
                            savings; business interruption; or for any indirect or consequential loss or damage.
                            <br/>
                            <br/>
                            13.5 If you are a consumer, you agree that you will not use Our Site for any commercial or
                            business purposes and that We shall have no liability to you for any business losses as set
                            out above.
                            <br/>
                            <br/>
                            13.6 Subject to Part 13.7, if you are a consumer and Our Content (digital content) from Our
                            Site (that is not User Content) damages other digital content or a device belonging to you,
                            where that damage is caused by Our failure to use reasonable skill and care, We will either
                            compensate you or repair the damage.
                            <br/>
                            <br/>
                            13.7 Note that the right to compensation or repair in Part 13.6 will be lost if the damage
                            in question could have been avoided by following advice or instructions from Us to install a
                            free patch or update; if the damage resulted from your failure to follow instructions; or if
                            the minimum system requirements provided by Us for the digital content in question were not
                            met.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            14. Viruses, Malware, and Security
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            14.1 We exercise reasonable skill and care to ensure that Our Site is secure and free from
                            viruses and malware; however, We do not guarantee that this is the case.
                            <br/>
                            <br/>
                            14.2 You are responsible for protecting your hardware, software, data, and other material
                            from viruses, malware, and other internet security risks.
                            <br/>
                            <br/>
                            14.3 You must not deliberately introduce viruses or other malware, or any other material
                            which is malicious or technologically harmful either to or via Our Site.
                            <br/>
                            <br/>
                            14.4 You must not attempt to gain unauthorised access to any part of Our Site, the server on
                            which Our Site is stored, or any other server, computer, or database connected to Our Site.
                            <br/>
                            <br/>
                            14.5 You must not attack Our Site by means of a denial of service attack, a distributed
                            denial of service attack, or by any other means.
                            <br/>
                            <br/>
                            14.6 By breaching the provisions of Parts 14.3 to 14.5, you may be committing a criminal
                            offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to
                            the relevant law enforcement authorities and We will cooperate fully with those authorities
                            by disclosing your identity to them. Your right to use Our Site will cease immediately in
                            the event of such a breach.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            15. Acceptable Usage of Our Site
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            15.1 You may only use Our Site in a lawful manner:
                            <br/>
                            <br/>
                            a) You must ensure that you comply fully with any and all local, national, or international
                            laws and regulations that apply;
                            <br/>
                            <br/>
                            b) You must not use Our site in any way, or for any purpose, that is unlawful or fraudulent;
                            and
                            <br/>
                            <br/>
                            c) You must not use Our Site to knowingly send, upload, or in any other way transmit data
                            that contains any form of virus or other malware or any other code designed to adversely
                            affect computer hardware, software, or data of any kind.
                            <br/>
                            <br/>
                            15.2 If you fail to comply with the provisions of this Part 15, you will be in breach of
                            these Terms of Use. We may take one or more of the following actions in response:
                            <br/>
                            <br/>
                            a) Suspend or terminate your right to use Our Site;
                            <br/>
                            <br/>
                            b) Issue you with a written warning;
                            <br/>
                            <br/>
                            c) Take legal proceedings against you for reimbursement of any and all relevant costs on an
                            indemnity basis resulting from your breach;
                            <br/>
                            <br/>
                            d) Take further legal action against you, as appropriate;
                            <br/>
                            <br/>
                            e) Disclose such information to law enforcement authorities as required or as We deem
                            reasonably necessary; and/or
                            <br/>
                            <br/>
                            f) Any other actions which We deem reasonably appropriate (and lawful).
                            <br/>
                            <br/>
                            15.3 We hereby exclude any and all liability arising out of any actions that We may take
                            (including, but not limited to those set out above in Part 15.3) in response to your breach.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            16. How We Use Your Personal Information
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            We will only use your personal information as set out in Our Privacy Policy, available
                            <br/>
                            from
                            <Link className={"mx-1 text-blue"}
                                  to={"/privacy-policy"}>www.puresportgroup.com/privacy</Link>
                            and Our Cookie Policy, available
                            <br/>
                            from
                            <Link className={"ms-1 text-blue"}
                                  // to={"/cookies"}>www.puresportgroup.com/cookies</Link>
                                  to={"/privacy-policy"}>www.puresportgroup.com/cookies</Link>
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            17. Communications from Us
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            17.1 If We have your contact details and/or if you have an Account, We may send you
                            important notices by email from time to time. Such notices may relate to matters including,
                            but not limited to, changes to Our Site or to t15.1 You may only use Our Site in a lawful
                            manner:
                            <br/>
                            <br/>
                            a) You must ensure that you comply fully with any and all local, national, or international
                            laws and regulations that apply;
                            <br/>
                            <br/>
                            b) You must not use Our site in any way, or for any purpose, that is unlawful or fraudulent;
                            and
                            <br/>
                            <br/>
                            c) You must not use Our Site to knowingly send, upload, or in any other way transmit data
                            that contains any form of virus or other malware or any other code designed to adversely
                            affect computer hardware, software, or data of any kind.
                            <br/>
                            <br/>
                            15.2 If you fail to comply with the provisions of this Part 15, you will be in breach of
                            these Terms of Use. We may take one or more of the following actions in response:
                            <br/>
                            <br/>
                            a) Suspend or terminate your right to use Our Site;
                            <br/>
                            <br/>
                            b) Issue you with a written warning;
                            <br/>
                            <br/>
                            c) Take legal proceedings against you for reimbursement of any and all relevant costs on an
                            indemnity basis resulting from your breach;
                            <br/>
                            <br/>
                            d) Take further legal action against you, as appropriate;
                            <br/>
                            <br/>
                            e) Disclose such information to law enforcement authorities as required or as We deem
                            reasonably necessary; and/or
                            <br/>
                            <br/>
                            f) Any other actions which We deem reasonably appropriate (and lawful).
                            <br/>
                            <br/>
                            15.3 We hereby exclude any and all liability arising out of any actions that We may take
                            (including, but not limited to those set out above in Part 15.3) in response to your breach.
                            <br/>
                            <br/>
                            These Terms of Use, or to your Account.
                            <br/>
                            <br/>
                            17.2 We will not send you marketing emails without your express consent. If you do consent
                            to marketing, you may opt out at any time. All marketing emails from Us include an
                            unsubscribe link.
                            <br/>
                            <br/>
                            17.3 For questions or complaints about communications from Us, please contact Us using the
                            details above in Part 3.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            18. What Happens if We Transfer this Agreement to Another Party
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            We may transfer (assign) Our obligations and rights under these Terms of Use to a third
                            party (this may happen, for example, if We sell Our business). If this Occurs, We will
                            inform you in writing. Your rights under these Terms of Use will not be affected and Our
                            obligations under these Terms of Use will be transferred to the third party who will remain
                            bound by them.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            19. Law and Jurisdiction
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            19.1 These Terms of Use, and the relationship between you and Us (whether contractual or
                            otherwise) shall be governed by, and construed in accordance with, English law.
                            <br/>
                            <br/>
                            19.2 If you are a consumer, you will benefit from any mandatory provisions of the law in
                            your country of residence. Nothing in Part 19.1 takes away from or reduces your legal rights
                            as a consumer.
                            <br/>
                            <br/>
                            19.3 If you are a consumer, any dispute, controversy, proceedings, or claim between you and
                            Us relating to these Terms of Use or to the relationship between you and Us (whether
                            contractual or otherwise) shall be subject to the jurisdiction of the courts of England,
                            Wales, Scotland, or Northern Ireland, as determined by your residency.
                            <br/>
                            <br/>
                            19.4 If you are a business user, any dispute, controversy, proceedings, or claim between you
                            and Us relating to these Terms of Use or to the relationship between you and Us (whether
                            contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of
                            England and Wales.
                        </div>
                        <br/>
                    </div>
                </section>
            </>
        )
    };
    return renderMain();
};

export default TermsOfUse