// SectionFive
import React from "react";
import sectionFiveImage from "../../Assets/Images/Sections/section-five.png";
import sectionFiveImageMobile from "../../Assets/Images/Sections/section-five-mobile.png";

import IconBlockchain from "../../Assets/Images/Icons/icon-blockchain-web3.png";
import IconPure from "../../Assets/Images/Icons/icon-pure-web3.png";
import IconUtility from "../../Assets/Images/Icons/icon-utility.png";

const SectionFive = (props) => {
    const {
        isMobile,
    } = props;

    const renderIconCard = (icon, heading, description) => {
        return (
            <>
                <div className={"d-flex flex-column gap-3 align-items-start "}>
                    <img
                        src={icon}
                        alt={"icon"}
                        className={"img-fluid"}
                        height={54}
                        width={54}
                        loading={"lazy"}
                    />
                    <div className={"gilroy fw-400 font-21 text-white"}>{heading}</div>
                    <div className={"gilroy-light fw-400 font-17 text-white opacity-50"}>{description}</div>
                </div>
            </>
        )
    }
    const renderLearnMoreButton = () => {
        return (
            <>
                <div role={"button"} className={"border-white px-3 py-2 rounded-5"}>
                    <div className={"gilroy fw-400 font-14 text-white"}>Learn more</div>
                </div>
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                <section className={`bg-blue py-4`}
                    // style={{height: "812px"}}
                >
                    <div className={"d-flex flex-column align-items-center container"}>
                        <div className={"gilroy fw-400 font-28 text-white my-2"}>Web3/Tokenomics</div>
                        <img
                            src={sectionFiveImageMobile}
                            alt={"section-image"}
                            className={"img-fluid"}
                            loading={"lazy"}
                        />
                        <div
                            className={"d-flex flex-column align-items-center align-items-center gap-3 mt-2"}
                        >

                            <div className={"gilroy-light fw-400 font-14 text-white text-center"}>
                                Where the future of sports fandom meets the pioneering world of Web3 technology. $PURE
                                is our native cryptocurrency, designed to enhance your experience and engagement within
                                the Pure Sport Group ecosystem. By leveraging the power of blockchain, we offer
                                unparalleled transparency, security, and rewards for our users.
                            </div>
                            <div className={"mt-4"}>
                                {renderLearnMoreButton()}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <section className={`bg-blue section-home-container`}>
                    <div className={"h-100 d-flex align-items-center justify-content-center"}>
                        <div className={`row h-100 p-0 m-0`}>
                            <div className={"col-6 d-flex align-items-center justify-content-center"}>
                                <img
                                    src={sectionFiveImage}
                                    alt={"section-image"}
                                    className={"web3-section-home-image"}
                                    loading={"lazy"}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div
                                    className={"d-flex flex-column align-items-start justify-content-center px-4 h-100 w-75"}>
                                    <div className={"gilroy fw-400 font-50 text-white"}>Web3/Tokenomics</div>
                                    <div
                                        className={"w-md-100 w-xl-75 gilroy-light fw-200 font-18 text-white opacity-50 my-2"}>
                                        Where the future of sports fandom meets the pioneering world of Web3 technology.
                                        $PURE is our native cryptocurrency, designed to enhance your experience and
                                        engagement within the Pure Sport Group ecosystem. By leveraging the power of
                                        blockchain, we offer unparalleled transparency, security, and rewards for our
                                        users.
                                    </div>
                                    <div className={"d-flex flex-column mt-3 gap-4 w-md-100 w-xl-75"}>
                                        <div className={"row col-12"}>
                                            <div className={"col-4"}>
                                                {renderIconCard(IconPure, "$PURE", "Deflationary utility token, offering incentives")}
                                            </div>
                                            <div className={"col-4"}>
                                                {renderIconCard(IconUtility, "Utility", "Primary currency, discounted for activities")}
                                            </div>
                                            <div className={"col-4"}>
                                                {renderIconCard(IconBlockchain, "Blockchain", "Solana, gaming audience")}
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className={"mt-4"}>*/}
                                    {/*    {renderLearnMoreButton()}*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </>
        );
    };


    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default SectionFive