import React, { useState, useEffect } from "react";

import BgFootball from "../../Assets/Images/Sports/bg-football.png";
import BgNBA from "../../Assets/Images/Sports/bg-nba.png";
import BgCricket from "../../Assets/Images/Sports/bg-cricket.png";
import BgFormula1 from "../../Assets/Images/Sports/bg-formula.png";
import BgTennis from "../../Assets/Images/Sports/bg-tennis.png";
import BgNFL from "../../Assets/Images/Sports/bg-nfl.png";
import BgMLB from "../../Assets/Images/Sports/bg-mlb.png";
import BgGolf from "../../Assets/Images/Sports/bg-golf.png";
import BgBoxing from "../../Assets/Images/Sports/bg-boxing.png";

import BgFootballHovered from "../../Assets/Images/Sports/bg-football-hovered.png";
import BgNBAHovered from "../../Assets/Images/Sports/bg-nba-hovered.png";
import BgCricketHovered from "../../Assets/Images/Sports/bg-cricket-hovered.png";
import BgFormula1Hovered from "../../Assets/Images/Sports/bg-formula-hovererd.png";
import BgTennisHovered from "../../Assets/Images/Sports/bg-tennis-hovered.png";
import BgNFLHovered from "../../Assets/Images/Sports/bg-nfl-hovered.png";
import BgMLBHovered from "../../Assets/Images/Sports/bg-mlb-hovered.png";
import BgGolfHovered from "../../Assets/Images/Sports/bg-golf-hovered.png";
import BgBoxingHovered from "../../Assets/Images/Sports/bg-boxing-hovered.png";

import LogoFootball from "../../Assets/Images/Sports/psg-football.png";
import LogoNBA from "../../Assets/Images/Sports/psg-nba.png";
import LogoCricket from "../../Assets/Images/Sports/psg-cricket.png";
import LogoFormula1 from "../../Assets/Images/Sports/psg-formula.png";
import LogoTennis from "../../Assets/Images/Sports/psg-tennis.png";
import LogoNFL from "../../Assets/Images/Sports/psg-nfl.png";
import LogoMLB from "../../Assets/Images/Sports/psg-mlb.png";
import LogoGolf from "../../Assets/Images/Sports/psg-golf.png";
import LogoBoxing from "../../Assets/Images/Sports/psg-boxing.png";

const SportBrands = (props) => {
    const { isMobile } = props;
    const [hoveredSport, setHoveredSport] = useState(null);

    const sportsData = [
        { name: "Football", bgImage: BgFootball, hoveredBgImage: BgFootballHovered, logo: LogoFootball },
        { name: "NBA", bgImage: BgNBA, hoveredBgImage: BgNBAHovered, logo: LogoNBA },
        { name: "Cricket", bgImage: BgCricket, hoveredBgImage: BgCricketHovered, logo: LogoCricket },
        { name: "Formula 1", bgImage: BgFormula1, hoveredBgImage: BgFormula1Hovered, logo: LogoFormula1 },
        { name: "Tennis", bgImage: BgTennis, hoveredBgImage: BgTennisHovered, logo: LogoTennis },
        { name: "NFL", bgImage: BgNFL, hoveredBgImage: BgNFLHovered, logo: LogoNFL },
        { name: "MLB", bgImage: BgMLB, hoveredBgImage: BgMLBHovered, logo: LogoMLB },
        { name: "Golf", bgImage: BgGolf, hoveredBgImage: BgGolfHovered, logo: LogoGolf },
        { name: "Boxing", bgImage: BgBoxing, hoveredBgImage: BgBoxingHovered, logo: LogoBoxing },
    ];

    useEffect(() => {
        sportsData.forEach((sport) => {
            const img = new Image();
            img.src = sport.hoveredBgImage;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderMobile = () => {
        return (
            <div className="row no-gutters col-12 p-0 m-0">
                {sportsData.map((sport, id) => (
                    <div className="col-12 p-0 m-0" key={id}>
                        <div className="sport-item position-relative" style={{ backgroundImage: `url(${sport.bgImage})` }}>
                            <img
                                src={sport.logo}
                                alt={`${sport.name} logo`}
                                className="sport-logo position-absolute bottom-0"
                                height={80}
                                width={250}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderLaptop = () => {
        return (
            <div className="row no-gutters col-12 p-0 m-0">
                {sportsData.map((sport, id) => (
                    <div className="col-12 col-md-6 col-lg-4 p-0 m-0" key={id}>
                        <div
                            className="sport-item position-relative"
                            style={{
                                backgroundImage: `url(${
                                    hoveredSport === id ? sport.hoveredBgImage : sport.bgImage
                                })`,
                            }}
                            onMouseEnter={() => setHoveredSport(id)}
                            onMouseLeave={() => setHoveredSport(null)}
                        >
                            <img
                                src={sport.logo}
                                alt={`${sport.name} logo`}
                                className="sport-logo position-absolute"
                                height={105}
                                width={300}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderMain = () => {
        return isMobile ? renderMobile() : renderLaptop();
    };

    return renderMain();
};

export default SportBrands;
