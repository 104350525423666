// ESportsHeroSection
import React from "react";

import {Card} from "react-bootstrap";

const ESportsHeroSection = (props) => {
    const {
        isMobile,
    } = props;

    const renderMain = () => {
        return (
            <>
                <section
                    className="d-flex justify-content-center align-items-center"
                    // style={{height: isMobile ? "554px" : "", marginTop:"-110px"}}
                    // style={{
                    //     // height: "691px",
                    //     marginTop: !isMobile && "-9%"
                    // }}
                >
                    {/*<Card className="border-0 rounded-0 position-relative w-100"*/}
                    {/*      style={{height: isMobile ? "60vh" : "70vh"}}*/}
                    {/*>*/}
                    {/*<Card className={`border-0 rounded-0 position-relative w-100 ${isMobile ? "" : "video-container"}`}*/}
                    {/*      style={{height: isMobile ? "554px" : ""}}*/}
                    {/*>*/}
                    {/*    <video*/}
                    {/*        className="position-absolute top-0 start-0 w-100 h-100 object-fit-fill img-fluid"*/}
                    {/*        style={{*/}
                    {/*            backgroundRepeat: "no-repeat",*/}
                    {/*            objectFit: "fill"*/}
                    {/*        }}*/}
                    {/*        autoPlay={true} loop muted*/}
                    {/*        playsInline={true}*/}
                    {/*        controls={false}*/}
                    {/*    >*/}
                    {/*        <source src={"https://cdn.fanzine.com/pure-sports-group/esports-page-header.mp4"} type="video/mp4"/>*/}
                    {/*        /!*Your browser does not support the video tag.*!/*/}
                    {/*    </video>*/}
                    <Card className={`border-0 rounded-0 position-relative w-100 ${isMobile ? "" : "video-container"}`}
                          style={{height: isMobile ? "554px" : "", marginTop:"-110px"}}
                    >

                        <video
                            className={`bg-blue position-absolute hero-section-video top-0 start-0 ${isMobile ?"h-100 w-100":"h-100 w-100"}`}
                            style={{
                                backgroundRepeat: "no-repeat",
                                // objectFit: "fill",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                // width: isMobile && "100%",
                                // height: isMobile && "100%",
                            }}
                            autoPlay={true}
                            loop
                            muted
                            playsInline={true}
                            controls={false}
                        >
                            <source src={"https://cdn.fanzine.com/pure-sports-group/esports-page-header.mp4"}
                                    type="video/mp4"/>
                            {/*Your browser does not support the video tag.*/}
                        </video>
                        <Card.Body
                            className="hero-section-text d-flex align-items-center justify-content-center position-relative"
                        >
                            <div
                                className={`d-flex align-items-center justify-content-center flex-column text-center ${isMobile ? "" : "h-100 mt-4 container"}`}>
                                {/*<div></div>*/}
                                <div className={"d-flex flex-column align-items-center justify-content-center gap-3"}>
                                    <div
                                        className={`gilroy fw-400 text-white ${isMobile ? "font-40" : "font-58"}`}
                                        style={{lineHeight: isMobile ? "45px" : "80px"}}
                                    >Esports
                                    </div>
                                    <div
                                        className={`gilroy-light fw-400 text-white opacity-100 ${isMobile ? "font-14" : "font-30"}`}>
                                        With the huge popularity and growth of esports globally, Pure Esports will be the hub for all gamers and esports enthusiasts.
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </>
        )
    };
    return renderMain();
};

export default ESportsHeroSection