// TokenLaunchedModal
import React from "react";
import {Modal} from "react-bootstrap";
import BackgroundModal from "../../Assets/Images/Modal/bg-popup.png";
import BackgroundModalModal from "../../Assets/Images/Modal/bg-popup-mobile.png";

import IconClose from "../../Assets/Images/Modal/icon-close.png";
import IconPsg from "../../Assets/Images/Modal/icon-psg.png";
import IconRocket from "../../Assets/Images/Modal/icon-rocket.png";
import IconDoc from "../../Assets/Images/Modal/icon-doc.png";
import IconX from "../../Assets/Images/Modal/icon-x.png";
import IconTelegram from "../../Assets/Images/Modal/icon-telegram.png";


const TokenLaunchedModal = (props) => {
    const {
        isMobile,
        show,
        handleClose
    } = props;

    const modalStyle = {
        backgroundImage: isMobile ? `url(${BackgroundModalModal})` : `url(${BackgroundModal})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: isMobile ? "553px" : "684px",
        // width:"100%"
    };


    const renderMobile = () => {
        return (
            <>
                <Modal
                    size={"lg"}
                    centered
                    show={show} onHide={handleClose}
                >
                    {/*<div style={modalStyle}>*/}

                    <Modal.Body style={modalStyle} className={"bg-blue border-none rounded m-0"}>
                        <div className={"d-flex align-items-center justify-content-end"}>
                            <img role={"button"} src={IconClose} alt={"close"} className={"img-fluid"} height={14}
                                 width={14} onClick={handleClose}/>
                        </div>
                        <div className={"d-flex flex-column align-items-center justify-content-between gap-2 h-100"}>
                            <div className={"d-flex flex-column align-items-center gap-2"}>
                                <img src={IconPsg} alt={"close"} className={"img-fluid"} height={124} width={124}/>
                                <div className={"d-flex align-items-center justify-content-center gap-2"}>
                                    {/*<img src={IconRocket} alt={"close"} className={"img-fluid"} height={40} width={40}/>*/}
                                    <div className={"gilroy-bold font-400 font-24 text-center text-white"}>
                                        Our token is now LIVE on UniSwap!
                                    </div>
                                    {/*<img src={IconRocket} alt={"close"} className={"img-fluid"} height={40} width={40}/>*/}
                                </div>
                                <div className={"gilroy-bold fw-400 font-16 text-white text-center"}>
                                    {/*Explore our Whitepaper and follow us on X for the latest updates!*/}
                                    Explore our Whitepaper, follow us on X and join our Telegram community for the latest updates!
                                </div>
                            </div>
                            <div className={"d-flex flex-column align-items-center justify-content-center gap-3 mb-5 "}>
                                <div className={"gilroy-bold fw-400 font-16 text-decoration-underline"} style={{color:"#39C3F9"}}>Find Our UniSwap Page Here</div>
                                <div className={"d-flex flex-row gap-3"}>
                                    <a href={"https://pure-esports.gitbook.io/puresportgroup"} target={"_blank"} rel={"noreferrer"}
                                       onClick={handleClose}>
                                        <img src={IconDoc} alt={"close"} className={"img-fluid"} height={68} width={68}/>
                                    </a>
                                    <a href={"https://x.com/PureEsports_GG"} target={"_blank"} rel={"noreferrer"} onClick={handleClose}>
                                        <img src={IconX} alt={"close"} className={"img-fluid"} height={68} width={68}/>
                                    </a>
                                    <a href={"https://t.me/puresportsgroup"} target={"_blank"} rel={"noreferrer"} onClick={handleClose}>
                                        <img src={IconTelegram} alt={"close"} className={"img-fluid"} height={68} width={68}/>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <Modal
                    size={"lg"}
                    centered
                    show={show} onHide={handleClose}
                >
                    {/*<div style={modalStyle}>*/}

                    <Modal.Body style={modalStyle} className={"bg-transparent border-none rounded m-0"}>
                        <div className={"d-flex align-items-center justify-content-end"}>
                            <img role={"button"} src={IconClose} alt={"close"} className={"img-fluid"} height={20}
                                 width={20} onClick={handleClose}/>
                        </div>
                        <div className={"d-flex flex-column align-items-center justify-content-between gap-2 h-100"}>
                            <div className={"d-flex flex-column align-items-center gap-2"}>
                                <img src={IconPsg} alt={"close"} className={"img-fluid"} height={124} width={124}/>
                                <div className={"d-flex align-items-center justify-content-center gap-2"}>
                                    <img src={IconRocket} alt={"close"} className={"img-fluid"} height={40} width={40}/>
                                    <div className={"gilroy-bold font-400 font-36 text-white"}> Our token is now LIVE on UniSwap!
                                    </div>
                                    <img src={IconRocket} alt={"close"} className={"img-fluid"} height={40} width={40}/>
                                </div>
                                <div className={"gilroy-bold fw-400 font-28 text-white text-center"}>
                                    {/*Explore our Whitepaper and follow us on X for the latest updates!*/}
                                    Explore our Whitepaper, follow us on X, and join our Telegram community for the latest updates!
                                </div>
                            </div>
                            <div className={"mb-5 pb-5 d-flex flex-column align-items-center justify-content-center gap-3"}>
                                <div className={"gilroy-bold fw-400 font-16 text-decoration-underline"} style={{color:"#39C3F9"}}>Find Our UniSwap Page Here</div>
                                <div className={"d-flex flex-row gap-3"}>
                                    <a href={"https://pure-esports.gitbook.io/puresportgroup"} target={"_blank"} rel={"noreferrer"}
                                       onClick={handleClose}>
                                        <img src={IconDoc} alt={"close"} className={"img-fluid"} height={90} width={90}/>
                                    </a>
                                    <a href={"https://x.com/PureEsports_GG"} target={"_blank"} rel={"noreferrer"} onClick={handleClose}>
                                        <img src={IconX} alt={"close"} className={"img-fluid"} height={90} width={90}/>
                                    </a>
                                    <a href={"https://t.me/puresportsgroup"} target={"_blank"} rel={"noreferrer"} onClick={handleClose}>
                                        <img src={IconTelegram} alt={"close"} className={"img-fluid"} height={90} width={90}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    };
    const renderMain = () => {
        return (
            <>
                <div className={"w-100"}>
                    {
                        isMobile ? renderMobile() : renderLaptop()
                    }
                </div>
            </>
        )
    };
    return renderMain();
};

export default TokenLaunchedModal;
