// ContactUsHeroSection
import React, {useState} from "react";
import {Form, Button, Col, Row} from 'react-bootstrap';
import IconX from "../../Assets/Images/Icons/icon-x-white.png";
import IconFacebook from "../../Assets/Images/Icons/icon-facebook-white.png";
import IconInstagram from "../../Assets/Images/Icons/icon-instagram-white.png";
import IconYoutube from "../../Assets/Images/Icons/icon-youtube-white.png";
import IconTicktok from "../../Assets/Images/Icons/icon-ticktok-white.png";
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactUsHeroSection = (props) => {
    const {
        isMobile,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData();
        formData.append("first_name", data?.firstName);
        formData.append("last_name", data?.lastName);
        formData.append("email", data?.email);
        formData.append("subject", data?.subject);
        if (data?.message) {
            formData.append("message", data?.message);
        }

        try {
            const response = await axios.post('https://test.esports.fanzine.com/api/v1/contactus', formData
            );

            if (response.status === 200) {
                // Handle successful response
                // console.log('Message sent successfully!');
                toast.success('Message sent successfully!');
                setData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            } else {
                // Handle server errors
                // console.log('Failed to send message. Please try again later.');
                toast.error('Message failed to send, please try again.');
            }
        } catch (error) {
            // Handle network errors
            // console.log('An error occurred.', error);
            toast.error('An error occurred. Please try again.');

        } finally {
            setIsLoading(false);
        }
    };

    const renderContactForm = () => {
        return (
            <>
                <Form onSubmit={handleSubmit} className={"d-flex flex-column gap-3"}>
                    <Row className={""}>
                        <Form.Group controlId="formFirstName" as={Col}>
                            <Form.Control
                                type="text"
                                name="firstName"
                                placeholder="First Name*"
                                value={data.firstName}
                                onChange={handleChange}
                                className="custom-input gilroy-bold fw-400 font-16"
                                required={true}
                            />
                        </Form.Group>

                        <Form.Group controlId="formLastName" as={Col}>
                            <Form.Control
                                type="text"
                                name="lastName"
                                placeholder="Last Name*"
                                value={data.lastName}
                                onChange={handleChange}
                                className="custom-input gilroy-bold fw-400 font-16"
                                required={true}
                            />
                        </Form.Group>
                    </Row>

                    <Form.Group controlId="formEmail">
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email*"
                            value={data.email}
                            onChange={handleChange}
                            className="custom-input gilroy-bold fw-400 font-16"
                            required={true}
                        />
                    </Form.Group>

                    <Form.Group controlId="formSubject">
                        <Form.Control
                            type="text"
                            name="subject"
                            placeholder="Subject*"
                            value={data.subject}
                            onChange={handleChange}
                            className="custom-input gilroy-bold fw-400 font-16"
                            required={true}
                        />
                    </Form.Group>

                    <Form.Group controlId="formMessage">
                        <Form.Control
                            as="textarea"
                            name="message"
                            rows={3}
                            placeholder="Your message..."
                            value={data.message}
                            onChange={handleChange}
                            className="custom-input gilroy-bold fw-400 font-16"
                            // required={true}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit"
                            className={"bg-skyblue rounded-5 px-3 py-2 submit-button"}>

                        <div className={"text-blue gilroy-bold fw-400 font-18"}>
                            {/*Send Message*/}
                            {isLoading ? 'Sending...' : 'Send Message'}
                        </div>
                    </Button>
                </Form>
            </>
        )
    }

    const renderSocial = () => {
        return (
            <>
                <div
                    className={`d-flex align-items-center justify-content-between flex-row gap-3 ${isMobile ? "w-75" : "w-50"}`}>
                    <a href="https://x.com/PureEsports_GG" target="_blank" rel="noopener noreferrer">
                        <img
                            src={IconX}
                            alt={"icon-tiktok"}
                            className={"img-fluid"}
                            // height={30}
                        />
                    </a>
                    <a href="https://www.facebook.com/pureesportsgg?locale=en_GB" target="_blank"
                       rel="noopener noreferrer">
                        <img
                            src={IconFacebook}
                            alt={"icon-tiktok"}
                            className={"img-fluid"}
                            // height={30}
                        />
                    </a>
                    <a href="https://www.instagram.com/pureesports_gg" target="_blank" rel="noopener noreferrer">
                        <img
                            src={IconInstagram}
                            alt={"icon-tiktok"}
                            className={"img-fluid"}
                            // height={30}
                        />
                    </a>
                    <a href="https://www.youtube.com/@PureEsportsGG" target="_blank" rel="noopener noreferrer">
                        <img
                            src={IconYoutube}
                            alt={"icon-tiktok"}
                            className={"img-fluid"}
                            // height={30}
                        />
                    </a>
                    <a href="https://www.tiktok.com/@pureesports" target="_blank" rel="noopener noreferrer">
                        <img
                            src={IconTicktok}
                            alt={"icon-tiktok"}
                            className={"img-fluid"}
                            // height={30}
                        />
                    </a>
                </div>
            </>
        )
    }

    const renderMobile = () => {
        return (
            <>
                <section
                    className="d-flex justify-content-center align-items-center contact-us-hero-section-mobile"
                >
                    <div className="contact-us-hero-section-background-mobile w-100 h-100 ">
                        <div
                            className="h-100 w-100"
                        >
                            <div className={"h-100 container d-flex align-items-start mt-5 pt-5 justify-content-start"}>
                                <div className={"d-flex flex-column gap-4"}>
                                    <div
                                        className={`gilroy-bold fw-400 text-white font-32 text-center`}
                                        style={{lineHeight: "46px"}}
                                    >
                                        Let’s talk
                                    </div>
                                    <div className={"text-center gilroy-bold fw-400 font-14 text-white"}>Questions,
                                        comments,
                                        or suggestions? Get in contact via the contact form below.
                                    </div>
                                    {renderContactForm()}
                                    <div className={"mt-4 d-flex flex-column align-items-center gap-3"}>
                                        <div className={"gilroy-bold fw-400 font-14 text-white"}>Find us on</div>
                                        {
                                            renderSocial()
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    };

    const renderLaptop = () => {
        return (
            <>
                <section
                    className="d-flex justify-content-center align-items-center contact-us-hero-section"
                >
                    <div className="contact-us-hero-section-background w-100 h-100 ">
                        <div
                            className=" position-absolute bottom-0 start-0 h-100 w-100">
                            <div className={"h-100 container d-flex align-items-center justify-content-center"}>
                                <div className={"d-flex flex-column gap-4"}>
                                    <div
                                        className={`gilroy-bold fw-400 text-white font-50 text-center`}
                                        style={{lineHeight: "46px"}}
                                    >
                                        Let’s talk
                                    </div>
                                    <div className={"text-center gilroy-bold fw-400 font-16 text-white"}>Questions,
                                        comments,
                                        or suggestions? Get in contact via the contact form below.
                                    </div>
                                    {renderContactForm()}
                                    <div className={"mt-4 d-flex flex-column align-items-center gap-3"}>
                                        <div className={"gilroy-bold fw-400 font-18 text-white"}>Find us on</div>
                                        {
                                            renderSocial()
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    };
    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
                <ToastContainer/>
            </>
        )
    };
    return renderMain();
};

export default ContactUsHeroSection