// SectionFour
import React from "react";
import sectionFourImage from "../../Assets/Images/Sections/sports_image.png";
import sportImageMobile from "../../Assets/Images/Sections/sports_image_mobile.png";
import IconApps from "../../Assets/Images/Icons/icon-apps.svg";
import IconSocialMedia from "../../Assets/Images/Icons/icon-socialmedia.svg";
import {Link} from "react-router-dom";

const SectionFour = (props) => {
    const {
        isMobile,
    } = props;

    const renderIconCard = (icon, heading, description) => {
        return (
            <>
                <div className={"d-flex flex-row gap-3 align-items-start"}>
                    <img
                        src={icon}
                        alt={"icon"}
                        className={"img-fluid"}
                        height={54}
                        width={54}
                        loading={"lazy"}
                    />
                    <div className={"d-flex flex-column align-items-start"}>
                        <div className={"gilroy fw-400 font-21 text-blue"}>{heading}</div>
                        <div className={"gilroy-light fw-400 font-17 text-blue"}>{description}</div>
                    </div>
                </div>
            </>
        )
    }
    const renderLearnMoreButton = () => {
        return (
            <>
                <div role={"button"} className={"border-blue px-3 py-2 rounded-5"}>
                    <Link to={"/sports"} className={"gilroy fw-400 font-14 text-blue text-decoration-none"}>Learn more</Link>
                </div>
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                <section className={`bg-white py-4`}
                    // style={{height: "812px"}}
                >
                    <div className={"d-flex flex-column align-items-center container"}>
                        <div className={"gilroy fw-400 font-28 text-blue mb-2"}>Sports</div>
                        <img
                            src={sportImageMobile}
                            alt={"section-image"}
                            className={"img-fluid"}
                            loading={"lazy"}
                        />
                        <div
                            className={"d-flex flex-column align-items-center align-items-center gap-3"}>

                            <div className={"gilroy-light fw-400 font-14 text-blue text-center"}>
                                Pure are building cutting-edge apps and websites that cater to the world's largest sports, delivering a hyper-personalized experience for every fan.
                                <br/>
                                <br/>
                                Whether you're an avid follower of football, basketball, cricket, tennis, golf, or any other major sport, Pure Sport Group seamlessly integrates all your favourite sports into one cohesive digital ecosystem.
                            </div>
                            <div>
                                {renderLearnMoreButton()}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <section className={`bg-white section-home-container`}>
                    <div className={"h-100 d-flex align-items-center justify-content-center"}>
                        <div className={`row h-100 m-0 p-0`}>
                            <div className={"col-6 d-flex align-items-center justify-content-end"}>
                                <div
                                    className={"d-flex flex-column align-items-start justify-content-center px-4 h-100 w-75"}>
                                    <div className={"gilroy fw-400 font-50 text-blue"}>Sports</div>
                                    <div
                                        className={"w-md-100 w-xl-75 gilroy-light fw-200 font-18 text-blue opacity my-2"}>
                                        Pure are building cutting-edge apps and websites that cater to the world's largest sports, delivering a hyper-personalized experience for every fan.
                                        <br/>
                                        <br/>
                                        Whether you're an avid follower of football, basketball, cricket, tennis, golf, or any other major sport, Pure Sport Group seamlessly integrates all your favourite sports into one cohesive digital ecosystem.
                                    </div>
                                    <div className={"d-flex flex-column mt-5 gap-4 w-md-100 w-xl-75"}>
                                        <div className={"row p-0"}>
                                            <div className={"col-6"}>
                                                {renderIconCard(IconApps, "Apps & Websites", "Creating apps and websites at a sport, league and team level")}
                                            </div>
                                            <div className={"col-6"}>
                                                {renderIconCard(IconSocialMedia, "Social Media", "Building audiences of scale to drive web traffic and app downloads")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mt-5"}>
                                        {renderLearnMoreButton()}
                                    </div>
                                </div>
                            </div>
                            <div className={"d-flex col-6 h-100 m-auto"}>
                                <img
                                    src={sectionFourImage}
                                    alt={"section-image"}
                                    className={"sport-section-home-image"}
                                    loading={"lazy"}
                                />
                            </div>
                        </div>
                    </div>

                </section>
            </>
        );
    };


    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default SectionFour