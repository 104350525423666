import React, {useState} from "react";
import SectionOne from "../../Components/HomeComponents/SectionOne";
import SectionTwo from "../../Components/HomeComponents/SectionTwo";
import SectionThree from "../../Components/HomeComponents/SectionThree";
import SectionFour from "../../Components/HomeComponents/SectionFour";
import SectionFive from "../../Components/HomeComponents/SectionFive";
import NewsSection from "../../Components/HomeComponents/NewsSection";
import TokenLaunchedModal from "../../Components/CustomModals/TokenLaunchedModal";

const HomePage = (props) => {
    const {
        isMobile,
    } = props;

    const [showModal, setShowModal] = useState(true);

    // useEffect(() => {
    //     const hasSeenModal = localStorage.getItem('hasSeenModal');
    //     if (!hasSeenModal) {
    //         setShowModal(true);
    //         localStorage.setItem('hasSeenModal', 'true');
    //     }
    // }, []);
    const handleClose = () => setShowModal(false);

    const renderMain = () => {
        return (
            <div style={{
                width:"100%"
            }}>
                <SectionOne
                    isMobile={isMobile}
                />
                <SectionTwo
                    isMobile={isMobile}
                />
                <SectionThree
                    isMobile={isMobile}
                />
                <SectionFour
                    isMobile={isMobile}
                />
                <SectionFive
                    isMobile={isMobile}
                />
                <NewsSection
                    isMobile={isMobile}
                />
                <TokenLaunchedModal
                    show={showModal}
                    handleClose={handleClose}
                    isMobile={isMobile}
                />
            </div>
        )
    };
    return (
        <>
            {renderMain()}
        </>
    );
};

export default HomePage