// PrivacyPolicy
import React from "react";

const PrivacyPolicy = (props) => {
    const {
        isMobile,
    } = props;


    const renderMain = () => {
        return (
            <>
                {
                    !isMobile &&
                    <div className={"bg-privacy-policy-header bg-blue"}></div>
                }
                <section className={`bg-white container py-5`}>
                    <div className={"d-flex flex-column align-items-center justify-content-center"}>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-28" : "font-60"}`}>Pure Privacy
                            Policy
                        </div>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Updated July
                            26, 2024
                        </div>
                    </div>
                    <div className={"mt-4"}>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Pure
                            Sport Group Ltd understands that your privacy is important to you and that you care about
                            how your personal data is used. We respect and value the privacy of everyone who visits this
                            app/website, (“Our Site”) and will only collect and use personal data in ways that are
                            described here, and in a way that is consistent with our obligations and your rights under
                            the law.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Please read this Privacy Policy carefully and ensure that you understand it.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            1. Definitions and Interpretation
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            In this Policy the following terms shall have the following meanings:
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;Account&quot;</div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means an account required to access and/or use certain areas and features of Our Site;
                            </div>
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;Cookie&quot;</div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means an account required to access and/or use certain areas and features of Our Site;
                            </div>
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 col-md-2 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>&quot;Cookie
                                Law&quot;</div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                means an account required to access and/or use certain areas and features of Our Site;
                            </div>
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            2. Information About Us
                        </div>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Our Site is owned and operated by Pure Sport Group Ltd, a limited company registered in
                            England under company number 15792048.
                            <br/>
                            <br/>
                            Registered address: 119c Eastbank Street, Southport, England, PR8 1DQ
                            <br/>
                            <br/>
                            Email address:
                            <a target={"_blank"} rel={"noreferrer"} className={"text-decoration-none text-blue ms-2"}
                               href="mailto:support@puresportgroup.com">support@puresportgroup.com</a>
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            3. What Does This Policy Cover?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            This Privacy Policy applies only to your use of Our Site. Our Site may contain links to
                            other websites. Please note that we have no control over how your data is collected, stored,
                            or used by other websites and we advise you to check the privacy policies of any such
                            websites before providing any data to them.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            4. What Is Personal Data?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Personal data is defined by the UK GDPR and the Data Protection Act 2018 (collectively, “the
                            Data Protection Legislation”) as ‘any information relating to an identifiable person who can
                            be directly or indirectly identified in particular by reference to an identifier’.
                            <br/>
                            <br/>
                            Personal data is, in simpler terms, any information about you that enables you to be
                            identified. Personal data covers obvious information such as your name and contact details,
                            but it also covers less obvious information such as identification numbers, electronic
                            location data, and other online identifiers.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            5. What Are My Rights?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Under the Data Protection Legislation, you have the following rights, which we will always
                            work to uphold:
                            <br/>
                            <br/>
                            a) The right to be informed about our collection and use of your personal data. This Privacy
                            Policy should tell you everything you need to know, but you can always contact us to find
                            out more or to ask any questions using the details in Part 15.
                            <br/>
                            <br/>
                            b) The right to access the personal data we hold about you. Part 13 will tell you how to do
                            this.
                            <br/>
                            <br/>
                            c) The right to have your personal data rectified if any of your personal data held by us is
                            inaccurate or incomplete. Please contact us using the details in Part 15 to find out more.
                            <br/>
                            <br/>
                            d) The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any
                            of your personal data that we hold. Please contact us using the details in Part 15 to find
                            out more.
                            <br/>
                            <br/>
                            e) The right to restrict (i.e. prevent) the processing of your personal data.
                            <br/>
                            <br/>
                            f) The right to object to us using your personal data for a particular purpose or purposes.
                            <br/>
                            <br/>
                            g) The right to withdraw consent. This means that, if we are relying on your consent as the
                            legal basis for using your personal data, you are free to withdraw that consent at any time.
                            <br/>
                            <br/>
                            h) The right to data portability. This means that, if you have provided personal data to us
                            directly, we are using it with your consent or for the performance of a contract, and that
                            data is processed using automated means, you can ask us for a copy of that personal data to
                            re-use with another service or business in many cases.
                            <br/>
                            <br/>
                            For more information about our use of your personal data or exercising your rights as
                            outlined above, please contact us using the details provided in Part 15.
                            <br/>
                            <br/>
                            It is important that your personal data is kept accurate and up-to-date. If any of the
                            personal data we hold about you changes, please keep us informed as long as we have that
                            data.
                            <br/>
                            <br/>
                            Further information about your rights can also be obtained from the Information
                            Commissioner’s Office or your local Citizens Advice Bureau.
                            <br/>
                            <br/>
                            If you have any cause for complaint about our use of your personal data, you have the right
                            to lodge a complaint with the Information Commissioner’s Office. We would welcome the
                            opportunity to resolve your concerns ourselves, however, so please contact us first, using
                            the details in Part 15.
                        </div>
                        <br/>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            6. What Data Do You Collect and How?
                        </div>
                        <br/>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Depending
                            upon your use of Our Site, we may collect and hold some or all of the personal and
                            non-personal data set out in the table below, using the methods also set out in the table.
                            Please also see Part 14 for more information about our use of Cookies and similar
                            technologies.
                        </div>
                        <br/>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-6 col-md-4 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Data
                                Collected
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>How
                                We Collect the Data
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className={"row d-flex align-items-center"}>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Identity Information including name, title, date of birth, gender, display pictures and
                                files information.
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Registration information, when you voluntarily provide us your personal details in order
                                to create an account or register to our Services.
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className={"row d-flex align-items-center"}>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Contact information including address, email address, telephone number.
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Both registration information and when you communicate with us, either sending us
                                messages, submitting content through our Services or applying to be a content
                                provider/editor, we collect the Personal Data that you provide us with.
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className={"row d-flex align-items-center"}>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Payment information including card details, bank account numbers.
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Payment details are collected security by for both In-App Purchases and Merchandise sold
                                in our Store.
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className={"row d-flex align-items-center"}>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Profile information including preferences, interests, login details, purchase history.
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                When you engage with any one of our games, contests, quizzes, chat forums, votes/polls,
                                surveys, or any expression of your opinion. Or when purchase, return, or exchange
                                merchandise or products offered through the Services
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className={"row d-flex align-items-center"}>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Technical information and tracking including IP address, browser type and version,
                                operating system.
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                When operating our Services, we use various technological tracking tools to collect
                                information regarding your use of our Services. Along with our partners, we may use
                                tracking technologies such as cookies, JavaScript, Beacons and Local Storage, which are
                                used to store content information and preferences. Refer to our Cookie Policy to learn
                                more about the tracking tools we use.
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className={"row d-flex align-items-center"}>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Third party information, including social network usernames, traffic analytics vendors,
                                fraud prevention agencies and others.
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                We may receive your data from third party providers who help us with the provision and
                                maintenance of our Services, when you register or log-in to our Services. We also
                                receive your data from publishers and advertisers who use our platform and other
                                services.
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            7. How Do You Use My Personal Data?
                        </div>
                        <br/>

                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Under the Data Protection Legislation, we must always have a lawful basis for using personal
                            data. The following table describes how we may use your personal data, and our lawful bases
                            for doing so:
                        </div>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-4 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>What
                                We Do
                            </div>
                            <div
                                className={`col-4 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>What
                                Data We Use
                            </div>
                            <div
                                className={`col-4 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Our
                                Lawful Basis
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Registering you on Our Site.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Identity and Contact information.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Legitimate interest: The provision of Service.
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Providing
                                and managing your Account.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Identity
                                and Profile information.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Legitimate
                                interest: The provision and improvement of our Services. Additionally, meeting all
                                Compliance, Legal and Regulatory requirements, using Personal Data to investigate
                                violations, as required to do so by law.
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Providing
                                and managing your access to Our Site.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Identity
                                and Contact information.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Legitimate
                                interest: The provision of Service and any service announcements.
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Personalising
                                and tailoring your experience on Our Site, our products and/or services for you.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Profile,
                                Technical and Third Party information.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Legitimate
                                interest: The personalisation of Our Site and Products/Services, using data analytics to
                                create new features, products and all-round improvements.
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Supplying our products and/or services to you.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Identity, Contact and Profile information.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Legitimate interest: We may sell you Merchandise or send you promotional material
                                concerning our Services, or our partners’ services, which we believe might interest you.
                                We may also display certain adverts, which we believe will be relevant for you.
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Managing payments for our products and/or services.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Payment information.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Legitimate
                                interest: In order to process your payments for purchases, fulfil refunds, returns, or
                                exchanges, and process the redemption of discounts. This data is usually processed by
                                our relevant service providers, and not retained by us.
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Communicating with you.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Identity
                                and Contact information.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>Legitimate
                                interest: We will keep you informed of latest updates to our Services and offers.
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Supplying you with information by email and/or post that you have opted-in-to.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Identity and Contact information.
                            </div>
                            <div
                                className={`col-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Legitimate interest: You may receive promotional and marketing information, as well as
                                announcements direct to your email account. However, you may ‘opt-out’ within the User
                                Settings of Our Site.
                            </div>
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            With your permission and/or where permitted by law, we may also use your personal data for
                            marketing purposes, which may include contacting you by email and/or telephone and/or text
                            message and/or post with information, news, and offers on our products and/or services. You
                            will not be sent any unlawful marketing or spam. We will always work to fully protect your
                            rights and comply with our obligations under the Data Protection Legislation and the Privacy
                            and Electronic Communications (EC Directive) Regulations 2003, and you will always have the
                            opportunity to opt-out. We will always obtain your express opt-in consent before sharing
                            your personal data with third parties for marketing purposes and you will be able to opt-out
                            at any time.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Third Parties whose content appears on Our Site may use third-party Cookies, as detailed
                            below in Part 14. Please refer to Part 14 for more information on controlling cookies.
                            Please note that we do not control the activities of such third parties, nor the data that
                            they collect and use themselves, and we advise you to check the privacy policies of any such
                            third parties.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            In some circumstances, where permitted or required by law, we may process your personal data
                            without your knowledge or consent. This will only be done within the bounds of the Data
                            Protection Legislation and your legal rights.
                        </div>

                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            8. How Long Will You Keep My Personal Data?
                        </div>
                        <br/>

                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            We will not keep your personal data for any longer than is necessary in light of the
                            reason(s) for which it was first collected. Your personal data will therefore be kept for
                            the following periods (or, where there is no fixed period, the following factors will be
                            used to determine how long it is kept):
                        </div>
                        <br/>
                        <br/>
                        <div className={"row"}>
                            <div
                                className={`col-6 col-md-4 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>What
                                Type of Data
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-bold fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>What
                                How Long We Keep It
                            </div>
                        </div>
                        <br/>
                        <div className={"row d-flex align-items-start align-items-md-center"}>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                Identity Information including name, title, date of birth, gender.
                                <br/>
                                <br/>
                                Contact information including address, email address, telephone number.
                                <br/>
                                <br/>
                                Payment information including card details, bank account numbers.
                                <br/>
                                <br/>
                                Profile information including preferences, interests, login details, purchase history.
                                <br/>
                                <br/>
                                Technical information and tracking including IP address, browser type and version,
                                operating system.
                            </div>
                            <div
                                className={`col-6 col-md-4 gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                                We will retain your Personal Data for as long as necessary to provide our Services, and
                                as necessary to comply with our legal obligations, resolve disputes, and enforce our
                                policies.
                                <br/>
                                <br/>
                                Retention periods will be determined considering the type of information collected and
                                the purpose for which it is collected, bearing in mind the requirements applicable to
                                the situation and the need to destroy outdated, unused information at the earliest
                                reasonable time.
                                <br/>
                                <br/>
                                Under applicable regulations, we will keep records containing personal data, account
                                opening, communications and anything else required by applicable laws and regulations.
                            </div>
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            9. How and Where Do You Store or Transfer My Personal Data?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            The security of your personal data is essential to us, and to protect your data, we take a
                            number of important measures, including the following:
                            <br/>
                            <br/>
                            <ul>
                                <li>limiting access to your personal data to those employees, agents, contractors, and
                                    other third parties with a legitimate need to know and ensuring that they are
                                    subject to duties of confidentiality;
                                </li>
                                <br/>
                                <li>procedures for dealing with data breaches (the accidental or unlawful destruction,
                                    loss, alteration, unauthorised disclosure of, or access to, your personal data)
                                    including notifying you and/or the Information Commissioner’s Office where we are
                                    legally required to do so;
                                </li>
                            </ul>
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            We will only store or transfer personal data in or to countries that are deemed to provide
                            an adequate level of protection for personal data. For further information about adequacy
                            decisions and adequacy regulations, please refer to the Information Commissioner’s Office.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Please contact us using the details below in Part 15 for further information about the
                            particular data protection safeguards used by us when transferring your personal data to a
                            third country.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            10. Do You Share My Personal Data?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            We will not share any of your personal data with any third parties for any purposes, subject
                            to the following exceptions.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            If we sell, transfer, or merge parts of our business or assets, your personal data may be
                            transferred to a third party. Any new owner of our business may continue to use your
                            personal data in the same way(s) that we have used it, as specified in this Privacy Policy.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            In some limited circumstances, we may be legally required to share certain personal data,
                            which might include yours, if we are involved in legal proceedings or complying with legal
                            obligations, a court order, or the instructions of a government authority.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            11. How Can I Control My Personal Data?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            In addition to your rights under the Data Protection Legislation, set out in Part 5, when
                            you submit personal data via Our Site, you may be given options to restrict our use of your
                            personal data. In particular, we aim to give you strong controls on our use of your data for
                            direct marketing purposes (including the ability to opt-out of receiving emails from us
                            which you may do by unsubscribing using the links provided in our emails and, at the point
                            of providing your details and by managing your Account).
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            12. Can I Withhold Information?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            You may access certain areas of Our Site without providing any personal data at all.
                            However, to use all features and functions available on Our Site you may be required to
                            submit or allow for the collection of certain data.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            You may restrict our use of Cookies. For more information, see Part 14 and our Cookie Policy
                            www.Pure Sport Group Ltd.com/cookies.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            13. How Can I Access My Personal Data?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            If you want to know what personal data we have about you, you can ask us for details of that
                            personal data and for a copy of it (where any such personal data is held). This is known as
                            a “subject access request”.
                            <br/>
                            <br/>
                            All subject access requests should be made in writing and sent to the email or postal
                            addresses shown in Part 15.
                            <br/>
                            <br/>

                            There is not normally any charge for a subject access request. If your request is
                            ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee may
                            be charged to cover our administrative costs in responding.
                            <br/>
                            <br/>

                            We will respond to your subject access request within less than one month of receiving it.
                            Normally, we aim to provide a complete response, including a copy of your personal data
                            within that time. In some cases, however, particularly if your request is more complex, more
                            time may be required up to a maximum of three months from the date we receive your request.
                            You will be kept fully informed of our progress.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            14. How Do You Use Cookies?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Our Site may place and access certain first-party Cookies on your computer or device.
                            First-party Cookies are those placed directly by us and are used only by us. We use Cookies
                            to facilitate and improve your experience of Our Site and to provide and improve our
                            products and services. We have carefully chosen these Cookies and have taken steps to ensure
                            that your privacy and personal data is protected and respected at all times.
                            <br/>
                            <br/>
                            By using Our Site, you may also receive certain third-party Cookies on your computer or
                            device. Third-party Cookies are those placed by websites, services, and/or parties other
                            than us. Third-party Cookies are used on Our Site for tracking and online-advertising
                            purposes. These Cookies are not integral to the functioning of Our Site and your use and
                            experience of Our Site will not be impaired by refusing consent to them.
                            <br/>
                            <br/>
                            All Cookies used by and on Our Site are used in accordance with current Cookie Law.
                            <br/>
                            <br/>
                            Before Cookies are placed on your computer or device, you will be shown a pop-up requesting
                            your consent to set those Cookies. By giving your consent to the placing of Cookies you are
                            enabling us to provide the best possible experience and service to you. You may, if you
                            wish, deny consent to the placing of Cookies; however certain features of Our Site may not
                            function fully or as intended. You will be given the opportunity to allow only first-party
                            Cookies and block third-party Cookies.
                            <br/>
                            <br/>
                            Certain features of Our Site depend on Cookies to function. Cookie Law deems these Cookies
                            to be “strictly necessary”. Your consent will not be sought to place these Cookies, but it
                            is still important that you are aware of them. You may still block these Cookies by changing
                            your internet browser’s settings as detailed below, but please be aware that Our Site may
                            not work properly if you do so. We have taken great care to ensure that your privacy is not
                            at risk by allowing them.
                            <br/>
                            <br/>
                            Our Site uses analytics services. Website analytics refers to a set of tools used to collect
                            and analyse anonymous usage information, enabling us to better understand how Our Site is
                            used. This, in turn, enables us to improve Our Site and the products and services offered
                            through it.
                            <br/>
                            <br/>
                            The analytics services used by Our Site uses Cookies to gather the required information. You
                            do not have to allow us to use these Cookies, however whilst our use of them does not pose
                            any risk to your privacy or your safe use of Our Site, it does enable us to continually
                            improve Our Site, making it a better and more useful experience for you.
                            <br/>
                            <br/>
                            In addition to the controls that we provide, you can choose to enable or disable Cookies in
                            your internet browser. Most internet browsers also enable you to choose whether you wish to
                            disable all Cookies or only third-party Cookies. By default, most internet browsers accept
                            Cookies, but this can be changed. For further details, please consult the help menu in your
                            internet browser or the documentation that came with your device.
                            <br/>
                            <br/>
                            You can choose to delete Cookies on your computer or device at any time, however you may
                            lose any information that enables you to access Our Site more quickly and efficiently
                            including, but not limited to, login and personalisation settings.
                            <br/>
                            <br/>
                            It is recommended that you keep your internet browser and operating system up-to-date and
                            that you consult the help and guidance provided by the developer of your internet browser
                            and manufacturer of your computer or device if you are unsure about adjusting your privacy
                            settings.
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            15. How Do I Contact You?
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            To contact us about anything to do with your personal data and data protection, including to
                            make a subject access request, please use the following details:
                            <br/>
                            <br/>
                            Email address:
                            <a target={"_blank"} rel={"noreferrer"} className={"text-decoration-none text-blue ms-2"}
                               href="mailto:support@puresportgroup.com">support@puresportgroup.com</a>
                            <br/>
                            <br/>
                            Postal Address: 119c Eastbank Street, Southport, England, PR8 1DQ
                        </div>
                        <br/>
                        <div className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-22" : "font-30"}`}>
                            16. Changes to this Privacy Policy
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            We may change this Privacy Notice from time to time. This may be necessary, for example, if
                            the law changes, or if we change our business in a way that affects personal data
                            protection.
                        </div>
                        <br/>
                        <div className={`gilroy-regular fw-400 text-blue ${isMobile ? "font-16" : "font-22"}`}>
                            Any changes will be immediately posted on Our Site and you will be deemed to have accepted
                            the terms of the Privacy Policy on your first use of Our Site following the alterations. We
                            recommend that you check this page regularly to keep up-to-date. This Privacy Policy was
                            last updated on 26th July 2024.

                        </div>
                    </div>

                </section>
            </>
        )
    };
    return renderMain();
};

export default PrivacyPolicy