// InvestorRelations
import React from "react";
import InvestorRelationHeroSection from "../../Components/InvestorRelationComponents/InvestorRelationHeroSection";
import {newsData} from "../../Configs/NewsData";
import NewsCard from "../../Components/ReUseAbleComponents/CardsComponents/NewsCard";

const InvestorRelations = (props) => {
    const {
        isMobile,
    } = props;

    const renderMobile = () => {
        return (
            <>
                <InvestorRelationHeroSection isMobile={isMobile}/>
                <section className={`bg-white py-4`}
                    // style={{height: "812px"}}
                >
                    {/*<div className={"d-flex flex-column align-items-center justify-content-center "}>*/}
                    {/*    <div className={"gilroy fw-400 font-28 text-blue"}>Latest news</div>*/}
                    {/*    <div className={"w-75 text-center gilroy-light fw-400 font-14 text-blue opacity my-2"}>Here’s the latest updates in the world of Pure Sport Group</div>*/}
                    {/*</div>*/}
                    <div className={"mb-3 px-3 pb-2 w-100 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                        {
                            newsData?.map((news, index) => {
                                return (
                                    <NewsCard
                                        key={index}
                                        isMobile={isMobile}
                                        background={"bg-white"}
                                        index={index}
                                        width={"90%"}
                                        imageHeight={213}
                                        image={news?.image}
                                        title={news?.title}
                                        description={news?.description}
                                        newsLink={news?.id}
                                        externalLink={news?.externalLink}
                                        link={news?.link}
                                    />
                                )
                            })
                        }
                    </div>
                    {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                    {/*    {renderLearnMoreButton()}*/}
                    {/*</div>*/}
                </section>
            </>
        )
    };


    const renderLaptop = () => {
        return (
            <>
                <InvestorRelationHeroSection isMobile={isMobile}/>
                <section className={`bg-white`}
                >
                    <div className={"container pb-5"}>
                        {/*<div className={"d-flex flex-column align-items-center justify-content-center "}>*/}
                        {/*    <div className={"gilroy fw-400 font-50 text-blue"}>Latest news</div>*/}
                        {/*    <div className={"gilroy-light fw-400 font-18 text-blue opacity my-2"}>*/}
                        {/*        Here’s the latest updates in the world of Pure Sport Group*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={"row mt-3 p-0"}>
                            {
                                newsData?.map((news, index) => {
                                    return (
                                        <div key={index}
                                             className={"col-4 mb-4"}>
                                            <NewsCard
                                                key={index}
                                                isMobile={isMobile}
                                                background={"bg-white"}
                                                index={index}
                                                width={"100%"}
                                                imageHeight={213}
                                                image={news?.image}
                                                title={news?.title}
                                                description={news?.description}
                                                newsLink={news?.id}
                                                externalLink={news?.externalLink}
                                                link={news?.link}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    };
    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default InvestorRelations