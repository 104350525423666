// SectionTwo
import React from "react";

// import {Swiper, SwiperSlide} from "swiper/react";
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/pagination";
// import "swiper/css/autoplay";
// import "swiper/css/grid";
// import 'swiper/css/navigation';
// import {Autoplay, FreeMode, Pagination} from "swiper/modules";

import MobileOne from "../../Assets/Images/Slider/SliderMobileOne.png";
import MobileTwo from "../../Assets/Images/Slider/SliderMobileTwo.png";
import LaptopOne from "../../Assets/Images/Slider/SliderLaptop.png";

import MobileCombine from "../../Assets/Images/Slider/SliderMobileCollectionOne.png";

import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

const SectionTwo = (props) => {
    const {
        isMobile,
    } = props;

    const sliderDataDesktop = [
        {
            MobileOne: MobileOne,
            MobileTwo: MobileTwo,
            LaptopOne: LaptopOne,
        },
        {
            MobileOne: MobileOne,
            MobileTwo: MobileTwo,
            LaptopOne: LaptopOne,
        },

    ];
    const sliderDataMobile = [
        {
            image: MobileCombine,
        },
        {
            image: LaptopOne,
        },
        {
            image: MobileTwo,
        }, {
            image: MobileOne,
        },

    ];

    const renderLearnMoreButton = () => {
        return (
            <>
                <div role={"button"} className={"learn-more-button px-3 py-2"}>
                    <Link to={"/product"} className={"gilroy fw-400 font-14 text-blue text-decoration-none"}>Learn more</Link>
                </div>
            </>
        )
    }
    const renderMobileSliderCard = (data) => {
        return (
            <>
                <Card
                    className={"p-0 m-0 border-0 rounded-0 mt-1"}
                >
                    <Card.Body className={`d-flex flex-row align-items-center justify-content-center`}>
                        <div className={`d-flex flex-column align-items-start justify-content-start`}>
                            <div className={`d-flex align-items-center pb-2`}
                                 // style={{height: "397px"}}
                            >
                                <img
                                    src={data?.image}
                                    alt={"slider-image"}
                                    className={"img-fluid"}
                                    // width={"100%"}
                                    // height={397}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </>
        )
    }
    const renderDesktopSliderCard = (data) => {
        return (
            <>
                <Card
                    className={"p-0 m-0 border-0 rounded-0"}
                >
                    <Card.Body className={`d-flex flex-row align-items-end justify-content-center p-0 m-0`}>
                        <div className={`d-flex flex-column align-items-start justify-content-start ps-5`}>
                            <div className={`d-flex align-items-center gap-3 w-100`}>
                                <img
                                    src={data?.MobileOne}
                                    alt={"slider-image"}
                                    className={"mobile-image-one"}
                                    loading={"lazy"}
                                    // width={189}
                                    // height={397}
                                />
                                <img
                                    src={data?.MobileTwo}
                                    alt={"slider-image"}
                                    className={"mobile-image-two"}
                                    loading={"lazy"}
                                    // width={191}
                                    // height={393}
                                />
                            </div>

                        </div>
                        <div className={`d-flex flex-column align-items-start justify-content-center gap-4`}>
                            <div className={""}>
                                <img
                                    src={data?.LaptopOne}
                                    alt={"slider-image"}
                                    className={"laptop-skeleton"}
                                    loading={"lazy"}
                                    // width={904}
                                    // height={504}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                <section className={`bg-white py-4`}>
                    <div className={"d-flex flex-column align-items-center"}>
                        <div className={`gilroy-bold fw-400 font-32 text-blue`}>Pure esports</div>
                        <div className={`gilroy-light fw-400 font-14 text-blue mb-3 w-75 text-center`}>
                            The ultimate platform for everyone interested in esports and gaming.
                        </div>
                        {renderMobileSliderCard(sliderDataMobile[0])}
                        {/*<Swiper*/}
                        {/*    id={"section-two-mobile-id"}*/}
                        {/*    autoplay={true}*/}
                        {/*    slidesPerView={1}*/}
                        {/*    grid={{*/}
                        {/*        rows: 1,*/}
                        {/*    }}*/}
                        {/*    spaceBetween={30}*/}
                        {/*    freeMode={false}*/}
                        {/*    pagination={{*/}
                        {/*        clickable: true,*/}
                        {/*        bulletClass: 'swiper-pagination-bullet',*/}
                        {/*    }}*/}
                        {/*    modules={[FreeMode, Pagination, Autoplay]}*/}
                        {/*    className={`w-100`}*/}
                        {/*>*/}
                        {/*    {sliderDataMobile?.map((data, index) => {*/}
                        {/*        return (*/}
                        {/*            <SwiperSlide*/}
                        {/*                virtualIndex={index + 1}*/}
                        {/*                key={index + "section-two-mobile"}>*/}
                        {/*                {*/}
                        {/*                    renderMobileSliderCard(data)*/}
                        {/*                }*/}
                        {/*            </SwiperSlide>*/}
                        {/*        );*/}
                        {/*    })}*/}
                        {/*</Swiper>*/}
                        <div className={""}>
                            {renderLearnMoreButton()}
                        </div>
                    </div>
                </section>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <section className={`bg-white blue-section-container`}>
                    <div className={"d-flex flex-column align-items-center pt-5 "}>
                        <div className={`gilroy-bold fw-400 font-50 text-blue`}>Pure esports</div>
                        <div className={`gilroy-light fw-400 font-23 text-blue mb-3`}>
                            The ultimate platform for everyone interested in esports and gaming.
                        </div>
                        <div className={"mt-2"}>
                            {renderDesktopSliderCard(sliderDataDesktop[0])}
                        </div>
                        <div className={`d-flex align-items-center justify-content-center`}>
                            {renderLearnMoreButton()}
                        </div>
                        {/*<Swiper*/}
                        {/*    id={"section-two-desktop-id"}*/}
                        {/*    autoplay={true}*/}
                        {/*    slidesPerView={1}*/}
                        {/*    grid={{*/}
                        {/*        rows: 1,*/}
                        {/*    }}*/}
                        {/*    spaceBetween={30}*/}
                        {/*    freeMode={false}*/}
                        {/*    pagination={{*/}
                        {/*        clickable: true,*/}
                        {/*        bulletClass: 'swiper-pagination-bullet',*/}
                        {/*    }}*/}
                        {/*    modules={[FreeMode, Pagination, Autoplay]}*/}
                        {/*    className={`w-100`}*/}
                        {/*>*/}
                        {/*    {sliderDataDesktop?.map((data, index) => {*/}
                        {/*        return (*/}
                        {/*            <SwiperSlide*/}
                        {/*                virtualIndex={index + 1}*/}
                        {/*                key={index + "section-two"}>*/}
                        {/*                {*/}
                        {/*                    renderDesktopSliderCard(data)*/}
                        {/*                }*/}
                        {/*            </SwiperSlide>*/}
                        {/*        );*/}
                        {/*    })}*/}
                        {/*</Swiper>*/}
                    </div>
                </section>
            </>
        );
    };


    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default SectionTwo