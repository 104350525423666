import React from "react";
import ESportsHeroSection from "../../Components/ESportsComponents/ESportsHeroSection";

import FC24 from "../../Assets/Images/Games/Thumbnails/fc-24-thumbnail.png";
import CS2 from "../../Assets/Images/Games/Thumbnails/cs2-thumbnail.png";
import LOL from "../../Assets/Images/Games/Thumbnails/lol-thumbnail.png";
import Dota2 from "../../Assets/Images/Games/Thumbnails/dota2-thumbnail.png";
import RocketLeague from "../../Assets/Images/Games/Thumbnails/rocket-league-thumbnail.png";
import Valorant from "../../Assets/Images/Games/Thumbnails/valorant-thumbnail.png";
import ImageSectionFullWidth from "../../Components/ReUseAbleComponents/Sections/ImageSectionFullWidth";

import NewsCard from "../../Components/ReUseAbleComponents/CardsComponents/NewsCard";

import PureEsportsImage from "../../Assets/Images/ESports/esports-section.png"
import SimpleSlider from "../../Components/ReUseAbleComponents/SlidersComponents/SimpleSlider";

import TopPlayerImage1 from "../../Assets/Images/ESports/Players/player-image-1.png";
import TopPlayerImage2 from "../../Assets/Images/ESports/Players/player-image-2.png";
import TopPlayerImage3 from "../../Assets/Images/ESports/Players/player-image-3.png";
import TopPlayerImage4 from "../../Assets/Images/ESports/Players/player-image-4.png";
import TopPlayerImage5 from "../../Assets/Images/ESports/Players/player-image-5.png";
import TopPlayerImage6 from "../../Assets/Images/ESports/Players/player-image-6.png";
import TopPlayerImage7 from "../../Assets/Images/ESports/Players/player-image-7.png";
import TopPlayerImage8 from "../../Assets/Images/ESports/Players/player-image-8.png";
import TopPlayerImage9 from "../../Assets/Images/ESports/Players/player-image-9.png";
import TopPlayerImage10 from "../../Assets/Images/ESports/Players/player-image-10.png";
import TopPlayerImage11 from "../../Assets/Images/ESports/Players/player-image-11.png";
import TopPlayerImage12 from "../../Assets/Images/ESports/Players/player-image-12.png";
import TopPlayerImage13 from "../../Assets/Images/ESports/Players/player-image-13.png";
import TopPlayerImage14 from "../../Assets/Images/ESports/Players/player-image-14.png";
import TopPlayerImage15 from "../../Assets/Images/ESports/Players/player-image-15.png";
import TopPlayerImage16 from "../../Assets/Images/ESports/Players/player-image-16.png";
import TopPlayerImage17 from "../../Assets/Images/ESports/Players/player-image-17.png";
import TopPlayerImage18 from "../../Assets/Images/ESports/Players/player-image-18.png";
import TopPlayerImage19 from "../../Assets/Images/ESports/Players/player-image-19.png";
import TopPlayerImage20 from "../../Assets/Images/ESports/Players/player-image-20.png";


import TopPlayerImageLogo1 from "../../Assets/Images/ESports/Players/logo-player-1.png";
import TopPlayerImageLogo2 from "../../Assets/Images/ESports/Players/logo-player-2.png";
import TopPlayerImageLogo3 from "../../Assets/Images/ESports/Players/logo-player-3.png";
import TopPlayerImageLogo4 from "../../Assets/Images/ESports/Players/logo-player-4.png";
import TopPlayerImageLogo5 from "../../Assets/Images/ESports/Players/logo-player-5.png";
import TopPlayerImageLogo6 from "../../Assets/Images/ESports/Players/logo-player-6.png";
import TopPlayerImageLogo7 from "../../Assets/Images/ESports/Players/logo-player-7.png";
import TopPlayerImageLogo8 from "../../Assets/Images/ESports/Players/logo-player-8.png";
import TopPlayerImageLogo9 from "../../Assets/Images/ESports/Players/logo-player-9.png";
import TopPlayerImageLogo10 from "../../Assets/Images/ESports/Players/logo-player-10.png";
import TopPlayerImageLogo11 from "../../Assets/Images/ESports/Players/logo-player-11.png";
import TopPlayerImageLogo12 from "../../Assets/Images/ESports/Players/logo-player-12.png";
import TopPlayerImageLogo13 from "../../Assets/Images/ESports/Players/logo-player-13.png";
import TopPlayerImageLogo14 from "../../Assets/Images/ESports/Players/logo-player-14.png";
import TopPlayerImageLogo15 from "../../Assets/Images/ESports/Players/logo-player-15.png";
import TopPlayerImageLogo16 from "../../Assets/Images/ESports/Players/logo-player-16.png";
import TopPlayerImageLogo17 from "../../Assets/Images/ESports/Players/logo-player-17.png";
import TopPlayerImageLogo18 from "../../Assets/Images/ESports/Players/logo-player-18.png";
import TopPlayerImageLogo19 from "../../Assets/Images/ESports/Players/logo-player-19.png";
import TopPlayerImageLogo20 from "../../Assets/Images/ESports/Players/logo-player-20.png";



import TeamLogo1 from "../../Assets/Images/ESports/Teams/team-logo-1.png";
import TeamLogo2 from "../../Assets/Images/ESports/Teams/team-logo-2.png";
import TeamLogo3 from "../../Assets/Images/ESports/Teams/team-logo-3.png";
import TeamLogo4 from "../../Assets/Images/ESports/Teams/team-logo-4.png";
import TeamLogo5 from "../../Assets/Images/ESports/Teams/team-logo-5.png";
import TeamLogo6 from "../../Assets/Images/ESports/Teams/team-logo-6.png";
import TeamLogo7 from "../../Assets/Images/ESports/Teams/team-logo-7.png";
import TeamLogo8 from "../../Assets/Images/ESports/Teams/team-logo-8.png";
import TeamLogo9 from "../../Assets/Images/ESports/Teams/team-logo-9.png";
import TeamLogo10 from "../../Assets/Images/ESports/Teams/team-logo-10.png";
import TeamLogo11 from "../../Assets/Images/ESports/Teams/team-logo-11.png";
import TeamLogo12 from "../../Assets/Images/ESports/Teams/team-logo-12.png";
import TeamLogo13 from "../../Assets/Images/ESports/Teams/team-logo-13.png";
import TeamLogo14 from "../../Assets/Images/ESports/Teams/team-logo-14.png";
import TeamLogo15 from "../../Assets/Images/ESports/Teams/team-logo-15.png";
import TeamLogo16 from "../../Assets/Images/ESports/Teams/team-logo-16.png";
import TeamLogo17 from "../../Assets/Images/ESports/Teams/team-logo-17.png";
import TeamLogo18 from "../../Assets/Images/ESports/Teams/team-logo-18.png";
import TeamLogo19 from "../../Assets/Images/ESports/Teams/team-logo-19.png";
import TeamLogo20 from "../../Assets/Images/ESports/Teams/team-logo-20.png";

import esportsImageMobile from "../../Assets/Images/Slider/SliderMobileCollectionOne.png";
import {newsData} from "../../Configs/NewsData";
import IconWorldWide from "../../Assets/Images/Icons/icon-world-wide.png";
import {Link} from "react-router-dom";

const ESports = (props) => {
    const {
        isMobile,
    } = props;

    const games = [
        {image: FC24},
        {image: CS2},
        {image: LOL},
        {image: Dota2},
        {image: RocketLeague},
        {image: Valorant},
    ]

    const topPlayersData = [
        {id: 1, image: TopPlayerImage1, icon: TopPlayerImageLogo1, gamerName: "N0tail", realName: "Johan Sundstein"},
        {id: 2, image: TopPlayerImage2, icon: TopPlayerImageLogo2, gamerName: "JerAx", realName: "Jesse Vainikka"},
        {id: 3, image: TopPlayerImage3, icon: TopPlayerImageLogo3, gamerName: "donk", realName: "Danil Kryshkovets"},
        {id: 4, image: TopPlayerImage4, icon: TopPlayerImageLogo4, gamerName: "s1mple", realName: "Oleksandr Olehovych Kostyliev"},
        {id: 5, image: TopPlayerImage5, icon: TopPlayerImageLogo5, gamerName: "Faker", realName: "Kim Geon-bu"},
        {id: 6, image: TopPlayerImage6, icon: TopPlayerImageLogo6, gamerName: "Canyon", realName: "Kim Geon-bu"},
        {id: 7, image: TopPlayerImage7, icon: TopPlayerImageLogo7, gamerName: "PHzin", realName: "Henrique"},
        {id: 8, image: TopPlayerImage8, icon: TopPlayerImageLogo8, gamerName: "Vejrgang", realName: "Anders Vejrgang"},
        {id: 9, image: TopPlayerImage9, icon: TopPlayerImageLogo9, gamerName: "Tenz", realName: "Tyson Van Ngo"},
        {id: 10, image: TopPlayerImage10, icon: TopPlayerImageLogo10, gamerName: "Zekken", realName: "Zachary Patrone"},
        {id: 11, image: TopPlayerImage11, icon: TopPlayerImageLogo11, gamerName: "Ibai", realName: "Ibai Llanos Garatea"},
        {id: 12, image: TopPlayerImage12, icon: TopPlayerImageLogo12, gamerName: "Shroud", realName: "Michael Grzesiek"},
        {id: 13, image: TopPlayerImage13, icon: TopPlayerImageLogo13, gamerName: "Bugha", realName: "Kyle Giersdorf"},
        {id: 14, image: TopPlayerImage14, icon: TopPlayerImageLogo14, gamerName: "Myth", realName: "Ali Kabbani"},
        {id: 15, image: TopPlayerImage15, icon: TopPlayerImageLogo15, gamerName: "Summit1g", realName: "Jaryd Russell Lazar"},
        {id: 16, image: TopPlayerImage16, icon: TopPlayerImageLogo16, gamerName: "Ninja", realName: "Richard Tyler Blevins"},
        {id: 17, image: TopPlayerImage17, icon: TopPlayerImageLogo17, gamerName: "J Wong", realName: "Justin Wong "},
        {id: 18, image: TopPlayerImage18, icon: TopPlayerImageLogo18, gamerName: "Symfuhny", realName: "Mason Lanier"},
        {id: 19, image: TopPlayerImage19, icon: TopPlayerImageLogo19, gamerName: "xQC", realName: "Félix Lengyel"},
        {id: 20, image: TopPlayerImage20, icon: TopPlayerImageLogo20, gamerName: "MKLeo", realName: "Leonardo López Pérez"},
    ];
    const topTeamsData = [
        {id: 1, icon: TeamLogo1, name: "Team Liquid"},
        {id: 2, icon: TeamLogo2, name: "OG"},
        {id: 3, icon: TeamLogo3, name: "Team Spirit"},
        {id: 4, icon: TeamLogo4, name: "Evil Geniuses"},
        {id: 5, icon: TeamLogo5, name: "Natus Vincere"},
        {id: 6, icon: TeamLogo6, name: "Fnatic"},
        {id: 7, icon: TeamLogo7, name: "Team Secret"},
        {id: 8, icon: TeamLogo8, name: "Virtus.pro"},
        {id: 9, icon: TeamLogo9, name: "FaZe Clan"},
        {id: 10, icon: TeamLogo10, name: "PSG Esports"},
        {id: 11, icon: TeamLogo11, name: "G2 Esports"},
        {id: 12, icon: TeamLogo12, name: "LGD Gaming"},
        {id: 13, icon: TeamLogo13, name: "Vici Gaming"},
        {id: 14, icon: TeamLogo14, name: "Invictus Gaming"},
        {id: 15, icon: TeamLogo15, name: "T1"},
        {id: 16, icon: TeamLogo16, name: "Newbee"},
        {id: 17, icon: TeamLogo17, name: "Cloud9"},
        {id: 18, icon: TeamLogo18, name: "OpTic Gaming"},
        {id: 19, icon: TeamLogo19, name: "All Gamers"},
        {id: 20, icon: TeamLogo20, name: "eStar Gaming"},
    ];

    const renderLearnMoreButton = () => {
        return (
            <>
                <div role={"button"} className={"border-blue px-3 py-2 rounded-5"}>
                    <Link to={"/product"} className={"gilroy fw-400 font-14 text-blue text-decoration-none"}>Learn more</Link>
                </div>
            </>
        )
    }

    const renderCard = (count, countHeading, isWordWideIcon) => {
        return (
            <>
                <div
                    className={`d-flex flex-column align-items-start px-3 py-2 rounded-4 bg-blue ${isMobile ? "count-card-mobile" : "count-card"}`}>
                    {
                        isWordWideIcon ?
                            <>
                                <img src={IconWorldWide} alt={"icon"} className={`mb-2`} height={59}/>
                                <div
                                    className={`gilroy-bold fw-400 text-white border-bottom-orange-4 w-25 ${isMobile ? "font-32" : "font-50"}`}></div>
                            </> :

                            <div
                                className={`gilroy-bold fw-400 text-white border-bottom-orange-4 w-25 ${isMobile ? "font-32" : "font-50"}`}
                                style={{lineHeight: "72px"}}>{count}</div>
                    }
                    <div className={`gilroy-bold fw-400 text-white pt-2 ${isMobile ? "font-12" : "font-18"}`}
                         style={{lineHeight: isMobile ? "14px" : "20px"}}>{countHeading}</div>
                </div>
            </>
        )
    }
    const renderCardTopPlayer = (data) => {
        return (
            <div className={"card w-100 bg-transparent border-0"}>
                <div className={"w-100 rounded-4"}>
                    <img src={data?.image} className="img-fluid rounded-4" alt={data?.gamerName}/>
                    {/*<div className={"position-absolute bottom-0 w-100 text-center mb-2"}>*/}
                    {/*    <img src={data?.icon} className="img-fluid rounded-circle" alt={data?.icon}/>*/}
                    {/*</div>*/}
                </div>
                <div className="card-body text-center">
                    <div
                        className={`gilroy fw-400 text-white ${isMobile ? "font-18" : "font-28"}`}>{data?.gamerName}</div>
                    <div
                        className={`gilroy-Light fw-400 text-white ${isMobile ? "font-14" : "font-22"}`}>{data?.realName}</div>
                </div>
            </div>
        )
    }
    const renderCardTopTeams = (data) => {
        return (
            <>
            {
                isMobile ?
                    <>
                        <div className={"card w-100  bg-transparent border-0 d-flex flex-column align-items-center justify-content-end h-100"}>
                            <div className={"card-img-top d-flex align-items-center justify-content-center h-100"}>
                                <img src={data?.icon} className="img-fluid" alt={data?.icon}/>
                            </div>
                            <div className="card-body p-0 mt-2 d-flex flex-column justify-content-end">
                                <div className={`gilroy fw-400 text-center text-blue ${isMobile ?"font-16":"font-28"}`}>{data?.name}</div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className={"e-sports-teams"}>
                            <div className={"d-flex flex-column align-items-center justify-content-end h-75 gap-4"}>
                                <img src={data?.icon} className="" alt={data?.icon}/>
                                <div className={`gilroy fw-400 text-center text-blue ${isMobile ?"font-16":"font-28"}`}>{data?.name}</div>
                            </div>
                        </div>
                    </>
            }
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                <ESportsHeroSection isMobile={isMobile}/>
                <section className={`bg-white pb-3`}
                    // style={{height: "812px"}}
                >
                    <div className={"d-flex flex-column align-items-center container py-5"}>
                        <div className={"gilroy fw-400 font-28 text-blue mb-2"}>Pure Esports</div>
                        <div className={"gilroy-Light text-center fw-400 font-14 text-blue mb-2"}>
                            The Ultimate esports and gaming platform
                        </div>
                        <img
                            src={esportsImageMobile}
                            alt={"section-image"}
                            className={"img-fluid"}
                        />
                        <div
                            className={"d-flex flex-column align-items-center align-items-center gap-3"}>
                            <div>
                                {renderLearnMoreButton()}
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`bg-blue`}>
                    <div className={"container py-5"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`gilroy fw-400 font-28 text-white`}>
                                Games
                            </div>
                            <div className={"gilroy-light fw-400 font-14 text-white-50 w-100 text-center"}>
                                Our immediate focus will be tournaments, education and aggregating news and video content for the following games
                            </div>
                        </div>
                        <div className={"pt-5 pb-4 w-100 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                            {
                                games.map((game, index) => {
                                    return (
                                        <div className={"col-7"} key={index}>
                                            <img
                                                role={"button"}
                                                src={game?.image}
                                                alt={"game"}
                                                className={"img-fluid rounded-4 game-image"}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
                <section className={"py-5"}>
                    <ImageSectionFullWidth
                        isMobile={isMobile}
                        isRight={true}
                        // image={SectionImageRight}
                        videoUrl={"https://cdn.fanzine.com/pure-sports-group/global-footprint-esports-page.mp4"}
                        title={"Global footprint"}
                        description={"The size of the industry doubled to almost $240 billion between 2017 and 2022."}
                        colorDescription={"orange"}
                        count1={"3.2BN"}
                        countHeading1={"Gamers worldwide"}
                        count2={"79%"}
                        countHeading2={"Internet users engage in gaming"}
                        count3={"46%"}
                        countHeading3={"Female"}
                        count4={"35"}
                        countHeading4={"Average age"}
                        count5={"4.3BN"}
                        countHeading5={"Projected market revenue for 2024"}
                        count6={"6M"}
                        countHeading6={"Asia is the biggest gaming region"}
                        isWordWideIcon={true}
                        globalFootPrint={true}
                    />
                </section>

                <section className={`bg-blue`}>
                    <div className={"py-5"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center gap-2`}>
                            <div className={`gilroy-bold fw-400 font-28 text-white`}>
                                Top players
                            </div>
                            <div className={"gilroy-light fw-400 font-14 text-white w-75 text-center"}>
                                All of the top players and streamers in the esports world
                            </div>
                        </div>
                        <div className={"w-100 mt-5 px-3 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                            {topPlayersData?.map((data, index) => {
                                return (
                                    <div key={index} className={"col-10"}>
                                        {
                                            renderCardTopPlayer(data)
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
                <section className={`bg-white`}>
                    <div className={"py-5"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center gap-2`}>
                            <div className={`gilroy fw-400 font-28 text-blue`}>
                                Top teams
                            </div>
                            <div className={"gilroy-light fw-400 font-14 text-blue w-75 text-center"}>
                                All of the top players and streamers in the esports world
                            </div>
                        </div>
                        <div className={"w-100 mt-5 px-3 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                            {topTeamsData?.map((data, index) => {
                                return (
                                    <div key={index} className={"col-4"}>
                                        {
                                            renderCardTopTeams(data)
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
                <section className={"pt-5 bg-blue"}>
                    <ImageSectionFullWidth
                        isMobile={isMobile}
                        isRight={false}
                        // image={StatsSectionImage}
                        videoUrl={"https://cdn.fanzine.com/pure-sports-group/games-clips-esports-page.mp4"}
                        title={"Stats we will cover"}
                        description={""}
                        colorDescription={"orange"}
                        count1={"325M"}
                        countHeading1={"EA FC copies sold"}
                        count2={"150M"}
                        countHeading2={"LoL players"}
                        count3={"85M"}
                        countHeading3={"Dota players"}
                        count4={"72M"}
                        countHeading4={"CS2 players"}
                        count5={"40M"}
                        countHeading5={"Rocket League players"}
                        count6={"19M"}
                        countHeading6={"Valorant players"}
                        isStatsESports={true}
                    />
                </section>

                <section className={`bg-white py-5`}
                    // style={{height: "812px"}}
                >
                    <div className={"d-flex flex-column align-items-center justify-content-center "}>
                        <div className={"gilroy fw-400 font-28 text-blue"}>Latest news</div>
                        <div className={"w-75 text-center gilroy-light fw-400 font-14 text-blue opacity my-2"}>Here’s the latest updates in the world of Pure Sport Group</div>
                    </div>
                    <div className={"my-3 px-3 py-2 w-100 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                        {
                            newsData?.map((news, index) => {
                                return (
                                    <NewsCard
                                        key={index}
                                        isMobile={isMobile}
                                        background={"bg-white"}
                                        index={index}
                                        width={"90%"}
                                        imageHeight={213}
                                        image={news?.image}
                                        title={news?.title}
                                        description={news?.description}
                                        newsLink={news?.id}
                                        externalLink={news?.externalLink}
                                        link={news?.link}
                                    />
                                )
                            })
                        }
                    </div>
                    {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                    {/*    {renderLearnMoreButton()}*/}
                    {/*</div>*/}
                </section>
            </>
        )
    };

    const renderLaptop = () => {
        return (
            <>
                <ESportsHeroSection isMobile={isMobile}/>
                <section className={`bg-white blue-section-container`}
                    // style={{height: "694px"}}
                >
                    <div className={"container-xxl h-100 d-flex align-items-center justify-content-center"}>
                        <div className={`row h-100`}>
                            <div className={"col-6"}>
                                <div
                                    className={"d-flex flex-column align-items-start justify-content-center px-4 h-100 gap-4"}>
                                    <div className={"d-flex flex-column"}>
                                        <div className={"gilroy-bold fw-400 font-50 text-blue"}>Pure Esports</div>
                                        <div className={`gilroy-bold fw-400 font-22 text-orange`}>
                                            The Ultimate esports and gaming platform
                                        </div>
                                    </div>
                                    <div className={"w-100 gilroy-medium fw-200 font-22 text-blue opacity-75 my-2"}>
                                        Pure is leading the way with its one-stop-shop platform which aggregates global content for both pro esports and amateur gaming.
                                        <br/>
                                        <br/>
                                        Underpinned by competitive tournaments and leagues along with professional educational content for academy courses, masterclasses and 1-2-1 coaching.
                                    </div>
                                    <div className={"mt-4"}>
                                        {renderLearnMoreButton()}
                                    </div>
                                </div>
                            </div>
                            <div className={"col-6 m-auto"}>
                                <img
                                    src={PureEsportsImage}
                                    alt={"section-image"}
                                    className={"esports-section-image-responsive"}
                                />
                            </div>
                        </div>
                    </div>

                </section>

                <section className={`bg-blue`}>
                    <div className={"container py-5"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`gilroy fw-400 font-50 text-white`}>
                                Games
                            </div>
                            <div className={"gilroy-light fw-400 font-18 text-white-50 w-75 text-center"}>
                                Our immediate focus will be tournaments, education and aggregating news and video content for the following games
                            </div>
                        </div>
                        <div className={"row mt-5 mb-5"}>
                            {
                                games.map((game, index) => {
                                    return (
                                        <div className={"col-2"} key={index}>
                                            <img
                                                role={"button"}
                                                src={game?.image}
                                                alt={"game"}
                                                className={"img-fluid rounded-4 game-image"}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
                {/*<ImageSectionFullWidth*/}
                {/*    isMobile={isMobile}*/}
                {/*    isRight={true}*/}
                {/*    // image={SectionImageRight}*/}
                {/*    videoUrl={"https://cdn.fanzine.com/pure-sports-group/global-footprint-esports-page.mp4"}*/}
                {/*    title={"Global footprint"}*/}
                {/*    description={"The esports market will be worth $9.3 billion by 2032 (Fortune Business Insights)"}*/}
                {/*    colorDescription={"orange"}*/}
                {/*    count1={"3.2BN"}*/}
                {/*    countHeading1={"Gamers worldwide"}*/}
                {/*    count2={"79%"}*/}
                {/*    countHeading2={"Internet users engage in gaming"}*/}
                {/*    count3={"46%"}*/}
                {/*    countHeading3={"Female"}*/}
                {/*    count4={"35"}*/}
                {/*    countHeading4={"Average age"}*/}
                {/*    count5={"9.3BN"}*/}
                {/*    countHeading5={"Projected market revenue for 2032   "}*/}
                {/*    count6={"6M"}*/}
                {/*    countHeading6={"Asia is the biggest gaming region"}*/}
                {/*    isWordWideIcon={true}*/}
                {/*    globalFootPrint={true}*/}
                {/*/>*/}
                <section className={`bg-white`}>
                    <div className={"pt-5 container"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center"}>
                            <div
                                className={`gilroy fw-400 font-50 text-blue`}>Global footprint</div>
                            <div
                                className={`gilroy fw-400 font-18 text-orange`}>The size of the industry doubled to almost $240 billion between 2017 and 2022.</div>
                        </div>
                        <div className={"py-5 w-100 d-flex align-items-center justify-content-center"}>
                            <div className={"row w-75 p-0 m-0 "}>
                                <div className={"col-6 col-xxl-4 mb-4"}>
                                    {renderCard("3.2BN", "Gamers worldwide")}
                                </div>
                                <div className={"col-6 col-xxl-4 mb-4"}>
                                    {renderCard("79%", "Internet users engage in gaming")}
                                </div>
                                <div className={"col-6 col-xxl-4 mb-4"}>
                                    {renderCard("46%", "Female")}
                                </div>
                                <div className={"col-6 col-xxl-4 mb-4"}>
                                    {renderCard("35", "Average age")}
                                </div>
                                <div className={"col-6 col-xxl-4 mb-4"}>
                                    {renderCard("4.3BN", "Projected market revenue for 2024")}
                                </div>
                                <div className={"col-6 col-xxl-4 mb-4"}>
                                    {renderCard("", "Asia is the biggest gaming region", "true")}
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
                <section className={`bg-blue`}>
                    <div className={"py-5"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`gilroy fw-400 font-50 text-white`}>
                                Top Players
                            </div>
                            <div className={"gilroy-light fw-400 font-20 text-white-50 w-75 text-center"}>
                                All of the top players and streamers in the esports world
                            </div>
                        </div>
                        <div className={"w-100 mt-5"}>
                            <SimpleSlider
                                forDiv={"players"}
                                data={topPlayersData}
                                renderCardTopPlayer={renderCardTopPlayer}
                                slidesPerView={3}
                                gap={20}
                            />
                        </div>
                    </div>
                </section>
                <section className={`bg-white`}>
                    <div className={"py-5"}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`gilroy fw-400 font-50 text-blue`}>
                                Top Teams
                            </div>
                            <div className={"gilroy-light fw-400 font-20 text-light-blue w-75 text-center"}>
                                All of the biggest esports teams globally
                            </div>
                        </div>
                        <div className={"w-100 mt-5"}>
                            <SimpleSlider
                                forDiv={"teams"}
                                data={topTeamsData}
                                renderCardTopPlayer={renderCardTopTeams}
                                slidesPerView={4}
                                gap={30}
                            />
                        </div>
                    </div>
                </section>
                <ImageSectionFullWidth
                    isMobile={isMobile}
                    isRight={false}
                    // image={StatsSectionImage}
                    videoUrl={"https://cdn.fanzine.com/pure-sports-group/games-clips-esports-page.mp4"}
                    title={"Stats we will cover"}
                    description={""}
                    colorDescription={"orange"}
                    count1={"325M"}
                    countHeading1={"EA FC copies sold"}
                    count2={"150M"}
                    countHeading2={"LoL players"}
                    count3={"85M"}
                    countHeading3={"Dota players"}
                    count4={"72M"}
                    countHeading4={"CS2 Players"}
                    count5={"40M"}
                    countHeading5={"Rocket League players"}
                    count6={"19M"}
                    countHeading6={"Valorant players"}
                    isStatsESports={true}
                />
                <section className={`bg-white`}
                    // style={{height: "694px"}}
                >
                    <div className={"container py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-blue"}>Latest news</div>
                            <div className={"gilroy-light fw-400 font-18 text-blue opacity my-2"}>
                                Here’s the latest updates in the world of Pure Sport Group
                            </div>
                        </div>
                        <div className={"row mt-3 p-0 py-3"}>
                            {
                                newsData?.map((news, index) => {
                                    return (
                                        <div key={index}
                                             className={"col-4 mb-4"}>
                                            <NewsCard
                                                key={index}
                                                isMobile={isMobile}
                                                background={"bg-white"}
                                                index={index}
                                                width={"100%"}
                                                imageHeight={213}
                                                image={news?.image}
                                                title={news?.title}
                                                description={news?.description}
                                                newsLink={news?.id}
                                                externalLink={news?.externalLink}
                                                link={news?.link}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                        {/*    {renderLearnMoreButton()}*/}
                        {/*</div>*/}
                    </div>
                </section>


            </>
        )
    };
    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default ESports