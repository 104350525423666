// InvestorRelationHeroSection
import React from "react";

import {Card} from "react-bootstrap";
import HeroBackground from "../../Assets/Images/Investors/investors-hero-bg.png";
import HeroBackgroundMobile from "../../Assets/Images/Investors/investors-hero-bg-mobile.png";

const InvestorRelationHeroSection = (props) => {
    const {
        isMobile,
    } = props;

    const cardStyles = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 0,
        height: 591,
    };

    const renderMobile = () => {
        return (
            <>
                {/*<section*/}
                {/*    className="d-flex justify-content-center align-items-center investors-hero-section-mobile"*/}
                {/*>*/}
                {/*    <div className="investors-hero-section-background-mobile w-100 h-100 ">*/}
                {/*        <div*/}
                {/*            className="h-100 w-100"*/}
                {/*        >*/}
                {/*            <div className={"h-100 container d-flex align-items-start mt-5 pt-5 justify-content-start"}>*/}
                {/*                <div className={"d-flex flex-column gap-4"}>*/}
                {/*                    <div*/}
                {/*                        className={`gilroy-bold fw-400 text-white font-32 text-center`}*/}
                {/*                        style={{lineHeight: "46px"}}*/}
                {/*                    >*/}
                {/*                        Let’s talk*/}
                {/*                    </div>*/}
                {/*                    <div className={"text-center gilroy-bold fw-400 font-14 text-white"}>Questions,*/}
                {/*                        comments,*/}
                {/*                        or suggestions? Get in contact via the contact form below.*/}
                {/*                    </div>*/}
                {/*                    /!*{renderContactForm()}*!/*/}
                {/*                    /!*<div className={"mt-4 d-flex flex-column align-items-center gap-3"}>*!/*/}
                {/*                    /!*    <div className={"gilroy-bold fw-400 font-14 text-white"}>Find us on</div>*!/*/}
                {/*                    /!*    {*!/*/}
                {/*                    /!*        renderSocial()*!/*/}
                {/*                    /!*    }*!/*/}
                {/*                    /!*</div>*!/*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <section
                    className="d-flex justify-content-center align-items-center"
                >
                    <Card className="border-0 rounded-0 position-relative w-100"
                          style={{height: "60vh"}}
                    >

                        <div className={"position-absolute top-0 start-0 w-100 h-100"}
                             style={{height: "591px"}}
                        >
                            <img
                                className={"h-100 w-100"}
                                src={HeroBackgroundMobile}
                                alt="Card background"
                                style={cardStyles}
                            />
                            {/*<div*/}
                            {/*    className={"about-us-hero-section-bg position-absolute bottom-0 start-0 h-100 w-100"}></div>*/}
                        </div>
                        <Card.Body
                            className="d-flex align-items-center justify-content-center position-relative"
                        >
                            <div
                                className={`d-flex align-items-center justify-content-evenly flex-column text-center ${isMobile ? "" : "h-100 w-50"}`}>
                                <div className={"d-flex flex-column align-items-center justify-content-center gap-3"}>
                                    <div
                                        className={`gilroy fw-400 text-white ${isMobile ? "font-40" : "font-58"}`}
                                        style={{lineHeight: isMobile ? "45px" : "80px"}}
                                    >
                                        Latest updates for investors
                                    </div>
                                    <div
                                        className={`gilroy-light fw-400 text-white opacity-100 ${isMobile ? "font-14" : "font-30"}`}>
                                        Here’s the latest news updates in the world of Pure Sport Group
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                {/*<section*/}
                {/*    className="d-flex justify-content-center align-items-center investor-hero-section"*/}
                {/*>*/}
                {/*    <div className="investor-hero-section-background w-100 h-100 ">*/}
                {/*        <div*/}
                {/*            className="position-absolute bottom-0 start-0 h-100 w-100">*/}
                {/*            <div className={"h-100 container d-flex align-items-center justify-content-center"}>*/}
                {/*                <div className={"d-flex flex-column gap-4"}>*/}
                {/*                    <div*/}
                {/*                        className={`gilroy-bold fw-400 text-white font-50 text-center`}*/}
                {/*                        style={{lineHeight: "46px"}}*/}
                {/*                    >*/}
                {/*                        Investor Centre*/}
                {/*                    </div>*/}
                {/*                    <div>*/}
                {/*                        */}
                {/*                    </div>*/}
                {/*                    /!*{renderContactForm()}*!/*/}
                {/*                    /!*<div className={"mt-4 d-flex flex-column align-items-center gap-3"}>*!/*/}
                {/*                    /!*    <div className={"gilroy-bold fw-400 font-18 text-white"}>Find us on</div>*!/*/}
                {/*                    /!*    {*!/*/}
                {/*                    /!*        renderSocial()*!/*/}
                {/*                    /!*    }*!/*/}
                {/*                    /!*</div>*!/*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <section
                    className="d-flex justify-content-center align-items-center investor-hero-section"

                >
                    <Card className="border-0 rounded-0 position-relative w-100"
                          style={{height: isMobile ? "60vh" : "591px"}}
                    >

                        <div className={"position-absolute top-0 start-0 w-100 h-100 object-fit-fill"}
                             style={{height: "591px"}}
                        >
                            <img
                                className={"h-100 w-100"}
                                src={HeroBackground}
                                alt="Card background"
                                style={cardStyles}
                            />
                            {/*<div*/}
                            {/*    className={"position-absolute bottom-0 start-0 h-100 w-100"}></div>*/}
                        </div>
                        <Card.Body
                            className="d-flex align-items-center justify-content-center position-relative"
                        >
                            <div
                                className={`d-flex align-items-center justify-content-center flex-column text-center ${isMobile ? "" : "h-100 container"}`}>
                                <div className={"d-flex flex-column align-items-center justify-content-center gap-3"}>
                                    <div
                                        className={`gilroy fw-400 text-white ${isMobile ? "font-40" : "font-58"}`}
                                        style={{lineHeight: isMobile ? "45px" : "80px"}}
                                    >
                                        Latest updates for investors
                                    </div>
                                    <div
                                        className={`gilroy-light fw-400 text-white opacity-100 ${isMobile ? "font-14" : "font-30"}`}>
                                        Here’s the latest news updates in the world of Pure Sport Group
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </>
        )
    };
    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default InvestorRelationHeroSection