// ImageSection
import React from 'react'

const ImageSection = (props) => {
    const {
        isMobile,
        isRightImage,
        image,
        title,
        description,
        colorDescription,
        points,
        sectionId,
    } = props;

    const renderMobile = () => {
        return (
            <>
                <div className={`d-flex flex-column align-items-center p-0 m-0 justify-content-center gap-3`}>
                    <div className={`d-flex align-items-center justify-content-center`}>
                        <div className={"d-flex flex-column align-items-center justify-content-center gap-1 w-75"}>
                            <div
                                className={`gilroy-bold fw-400 font-28 ${isRightImage ? "text-blue" : "text-white"}`}>{title}</div>
                            <div className={`gilroy-bold fw-400 font-14 text-center text-${colorDescription}`}
                                 style={{lineHeight: "25px"}}>{description}</div>
                        </div>
                    </div>
                    <div className={`d-flex flex-column gap-3 align-items-center align-items-center`}>
                        <div className={"text-center"}>
                            <img
                                src={image}
                                // className={"img-fluid"}
                                className={`${sectionId  === "discord" ? "news-image-discord-mobile": sectionId === "wallet" ? "wallet-image-discord- img-fluid" : "img-fluid"}`}
                                alt={"section"}
                                // height={499}
                                // width={374}
                            />
                        </div>
                        <div className={"ps-3"}>

                            <div className={`gilroy-medium fw-400 font-14 ${isRightImage ? "text-blue" : "text-white"}`}
                                 style={{lineHeight: "19px"}}
                                 dangerouslySetInnerHTML={{__html: points}}
                            >

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    };

    const renderLaptop = () => {
        return (
            <>
                <div
                    className={`row d-flex align-items-center p-0 m-0 justify-content-start ${isRightImage ? "flex-row" : "flex-row-reverse"}`}>
                    <div className={`m-auto ${isRightImage ? "col-6 col-xl-4 col-xxl-6" : "col-6"}`}>
                        <div className={"d-flex flex-column gap-5"}>
                            <div className={"d-flex flex-column"}>
                                <div
                                    className={`gilroy-bold fw-400 font-50 ${isRightImage ? "text-blue" : "text-white"}`}>{title}</div>
                                <div
                                    className={`gilroy-bold fw-400 font-22  text-${colorDescription}`}>{description}</div>
                            </div>
                            <div className={`gilroy-medium fw-400 font-22 ${isRightImage ? "text-blue" : "text-white"}`}
                                 style={{lineHeight: "27.18px"}}
                                 dangerouslySetInnerHTML={{__html: points}}
                            >
                            </div>
                        </div>
                    </div>
                    <div
                        className={`d-flex ${isRightImage ? `col-6 col-xl-8 col-xxl-6 ${sectionId === "discord" ? "justify-content-center" : "justify-content-center"}` : "col-5 justify-content-start"}`}>
                        <div className={""}>
                            <img
                                src={image}
                                // className={`${sectionId  === "news" ? "news-image-product": sectionId === "teams"?"teams-image-product":"img-fluid"}`}
                                className={`img-fluid`}
                                alt={"section"}
                                // height={499}
                                // width={374}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    };

    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
}

export default ImageSection