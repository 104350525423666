// customizeHtmlContent.js

const customizeHtmlContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const transformElement = (element) => {
        if (element.tagName === 'P') {
            element.className = 'gilroy-medium fw-400 font-18';
        } else if (element.tagName === 'H1') {
            element.className = 'gilroy-bold fw-400 font-22';
        }else if (element.tagName === 'H6') {
            element.className = 'gilroy-light fw-400 font-28';
        } else if (element.tagName === 'UL') {
            element.className = 'gilroy-medium fw-400 font-18 custom-list';
        } else if (element.tagName === 'LI') {
            element.className = 'gilroy-medium fw-400 font-18';
            element.innerHTML = `${element.innerHTML}`;
        }

        Array.from(element.children).forEach(transformElement);
    };

    Array.from(doc.body.children).forEach(transformElement);

    return { __html: doc.body.innerHTML };
};

export default customizeHtmlContent;
