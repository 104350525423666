// FooterComponent
import React from "react";
import {Link} from "react-router-dom";
import Logo from "../../Assets/Images/Logos/Logo-Primary.png";
import {FaFacebookF} from "react-icons/fa";
import {FaInstagram, FaYoutube} from "react-icons/fa6";
import IconTicktok from "../../Assets/Images/Icons/icon-tiktok.svg";
import IconX from "../../Assets/Images/Icons/icon-x.png";

const FooterComponent = (props) => {
    const {
        isMobile,
        routes,
    } = props;
    const currentYear = new Date().getFullYear();

    const renderMain = () => {
        return (
            <>
                <section className={`bg-blue py-5`}>
                    <div className={`container d-flex align-items-center justify-content-center`}>
                        <div className={`d-flex flex-column align-items-center gap-4 w-100`}>
                            <Link to={"/"} className={"text-decoration-none"}>
                                <img
                                    src={Logo}
                                    alt="Logo"
                                    width={221}
                                    height={83}
                                />
                            </Link>
                            <div
                                className={`d-flex align-items-center ${isMobile ? "flex-row flex-wrap w-75  justify-content-between gap-3" : "flex-row w-50 justify-content-evenly"}`}>
                                {routes.map((route, index) => (
                                    <Link key={index} to={route?.href}
                                          className={`gilroy fw-400 text-white opacity-75 nav-link ${isMobile ? "font-16 col-5 text-nowrap" : "font-14"}`}>
                                        {route.name}
                                    </Link>
                                ))}
                            </div>
                            <div
                                className={`d-flex align-items-center justify-content-between flex-row mt-3 ${isMobile ? "w-75" : "w-25"}`}>
                                <a href="https://x.com/PureEsports_GG" target="_blank" rel="noopener noreferrer">
                                    {/*<AiOutlineTwitter color={"#39C3F9"} size={19}/>*/}
                                    <img
                                        src={IconX}
                                        alt={"icon-x"}
                                        height={19}
                                    />
                                </a>
                                <a href="https://www.instagram.com/pureesports_gg" target="_blank" rel="noopener noreferrer">
                                    <FaInstagram color={"#39C3F9"} size={19}/>
                                </a>
                                <a href="https://www.facebook.com/pureesportsgg?locale=en_GB" target="_blank" rel="noopener noreferrer">
                                    <FaFacebookF color={"#39C3F9"} size={19}/>
                                </a>
                                <a href="https://www.youtube.com/@PureEsportsGG" target="_blank" rel="noopener noreferrer">
                                    <FaYoutube color={"#39C3F9"} size={24}/>
                                </a>
                                <a href="https://www.tiktok.com/@pureesports" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={IconTicktok}
                                        alt={"icon-tiktok"}
                                        height={19}
                                    />
                                </a>
                            </div>
                            <div className={`d-flex text-center align-items-center gap-2 gilroy-regular text-lightgray fw-400 ${isMobile ? "font-12 flex-column":"font-12 flex-row"}`}>
                                    © {currentYear} PureSportGroup. All rights reserved.
                                <div className={"d-flex flex-row align-items-center gap-1"}>
                                    <span className={"px-1"}>|</span>
                                    <Link to="/terms-of-use" className={"text-decoration-none text-lightgray "}>Terms of Use</Link>
                                    <span className={"px-1"}>|</span>
                                    <Link to="/privacy-policy" className={"text-decoration-none text-lightgray "}>Privacy Policy</Link>
                                    <span className={"px-1"}>|</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    };
    return renderMain();
};

export default FooterComponent