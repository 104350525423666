// NavbarComponent
import React, {useState} from "react";
import Logo from "../../Assets/Images/Logos/Logo-Primary.png";

import {Navbar, Nav, Container} from 'react-bootstrap';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {HiBars3BottomRight} from "react-icons/hi2";
import {LiaTimesSolid} from "react-icons/lia";

const NavbarComponent = (props) => {
    const {
        isMobile,
        routes,
    } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleMouseEnter = () => {
        setDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setDropdownOpen(false);
    };

    const handleNavigate = (section) => {
        handleMouseLeave();
        navigate(`/product#${section}`);
    };

    // const renderDropdown = () => (
    //     <div
    //         className={`dropdown-menu dropdown-menu-nav-bar bg-skyblue-nav d-flex flex-column px-3 gap-2 border-0 ${dropdownOpen ? 'show' : ''}`}
    //         onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('tournaments')}>Tournaments
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('education')}>Education
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('games')}>Games
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('matches')}>Matches
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('news')}>News
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('watch')}>Watch
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('teams')}>Teams
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('players')}>Players
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('discord')}>Discord
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('favourites')}>Favourites
    //         </div>
    //         <div role={"button"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
    //              onClick={() => handleNavigate('wallet')}>Wallet
    //         </div>
    //     </div>
    // );
    const renderDropdown = () => (
        <div
            className={`dropdown-menu dropdown-menu-nav-bar bg-skyblue-nav d-flex flex-column px-3 gap-2 border-0 ${dropdownOpen ? 'show' : ''}`}
            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Link to={"/product#tournaments"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('tournaments')}>Tournaments
            </Link>
            <Link to={"/product#education"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('education')}>Education
            </Link>
            <Link to={"/product#games"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('games')}>Games
            </Link>
            <Link to={"/product#matches"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('matches')}>Matches
            </Link>
            <Link to={"/product#news"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('news')}>News
            </Link>
            <Link to={"/product#watch"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('watch')}>Watch
            </Link>
            <Link to={"/product#teams"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('teams')}>Teams
            </Link>
            <Link to={"/product#players"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('players')}>Players
            </Link>
            <Link to={"/product#discord"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('discord')}>Discord
            </Link>
            <Link to={"/product#favourites"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('favourites')}>Favourites
            </Link>
            <Link to={"/product#wallet"} className="dropdown-item-nav-bar gilroy-bold fw-400 font-14"
                  onClick={() => handleNavigate('wallet')}>Wallet
            </Link>
        </div>
    );

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };
    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    const renderMobile = () => {
        return (
            <>
                <Navbar expand="lg" className="bg-transparent z-2">
                    {
                        mobileMenuOpen ?
                            <>
                                <Navbar.Collapse
                                    id="responsive-navbar-nav"
                                    className={"w-100 d-flex align-items-start justify-content-start flex-row pt-2 bg-navy fixed-top bg-skyblue "}
                                    style={{zIndex: "2", height: "100vh"}}>

                                    <Nav className="w-100 h-100 px-3 d-flex flex-column gap-1">
                                        {routes.map((route, index) => (
                                            <Nav.Item key={index}>
                                                <Link key={index} to={route?.href}
                                                      className={"gilroy fw-400 font-14 text-white nav-link"}
                                                      onClick={closeMobileMenu}
                                                >
                                                    {route.name}
                                                </Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleMobileMenu}
                                                   className={"px-3 custom-toggler"}
                                    >
                                        <LiaTimesSolid className="text-blue" size={20}/>
                                    </Navbar.Toggle>
                                </Navbar.Collapse>
                            </>
                            : <>
                                <Link to="/" className={"px-3 navbar-brand"}>
                                    <img
                                        src={Logo}
                                        alt="Logo"
                                        // className={"img-fluid"}
                                        width={221}
                                        height={83}
                                    />
                                </Link>

                                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleMobileMenu}
                                               className={"px-3 custom-toggler"}>
                                    <HiBars3BottomRight className="text-white font-40"/>
                                </Navbar.Toggle>
                            </>
                    }
                </Navbar>
            </>
        )

    }
    const renderLaptop = () => {
        return (
            <>
                <Navbar expand="lg" className={`bg-transparent z-3 ${isMobile ? "w-100" : "w-100 container"}`}>
                    <Container className={`d-flex flex-row align-items-center justify-content-between`}>
                        <Navbar.Brand href="/">
                            <img
                                src={Logo}
                                alt="Logo"
                                // className={"img-fluid"}
                                width={221}
                                height={83}
                            />
                        </Navbar.Brand>
                        {/*<Navbar.Brand href="/" className="d-lg-none mx-auto">*/}
                        {/*    <img*/}
                        {/*        src={Logo}*/}
                        {/*        alt="Logo"*/}
                        {/*        width={221}*/}
                        {/*        height={83}*/}
                        {/*    />*/}
                        {/*</Navbar.Brand>*/}
                        <Navbar.Toggle aria-controls="basic-navbar-nav">
                            <HiBars3BottomRight className="text-white font-40"/>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav
                                className={`w-100 ${isMobile ? "ml-auto" : "d-flex align-items-center justify-content-end gap-4"}`}>
                                {/*{routes.map((route, index) => (*/}
                                {/*    <Link key={index} to={route?.href}*/}
                                {/*          // className={"gilroy fw-400 font-14 text-white nav-link"}*/}
                                {/*          className={`gilroy fw-400 font-14 text-white nav-link ${location.pathname === route.href ? 'active-link' : ''}`}*/}
                                {/*    >*/}
                                {/*        {route.name}*/}
                                {/*    </Link>*/}
                                {/*))}*/}
                                {routes.map((route, index) => (
                                    <div key={index} onMouseEnter={route.name === 'Product' ? handleMouseEnter : null}
                                         onMouseLeave={route.name === 'Product' ? handleMouseLeave : null}>
                                        {
                                            route.name === 'Product' ?
                                                <>
                                                    <Link key={index} to={route?.href}
                                                          className={`gilroy fw-400 font-14 nav-link ${location.pathname === route.href ? 'active-link text-white' : ''} ${dropdownOpen ? "bg-skyblue-nav text-blue" : "text-white"}`}
                                                    >
                                                        {route.name}
                                                    </Link>
                                                </>
                                                :
                                                <>
                                                    <Link key={index} to={route?.href}
                                                          className={`gilroy fw-400 font-14 text-white nav-link ${location.pathname === route.href ? 'active-link' : ''}`}
                                                    >
                                                        {route.name}
                                                    </Link>
                                                </>
                                        }

                                        {route.name === 'Product' && dropdownOpen && renderDropdown()}
                                    </div>
                                ))}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        )
    };

    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    }
    return renderMain();
};

export default NavbarComponent