// Product
import React, {useEffect} from "react";
import ProductHeroSection from "../../Components/ProductComponents/ProductHeroSection";
import ImageSection from "../../Components/ReUseAbleComponents/Sections/ImageSection";

import TournamentImage from "../../Assets/Images/Product/tournament-image.png";
import EducationImage from "../../Assets/Images/Product/education-image.png";
import GamesImage from "../../Assets/Images/Product/games-collection-image.png";
import MatchesImage from "../../Assets/Images/Product/matches.png";
import NewsImage from "../../Assets/Images/Product/news.png";
import WatchImage from "../../Assets/Images/Product/watch.png";
import TeamsImage from "../../Assets/Images/Product/teams.png";
import PlayersImage from "../../Assets/Images/Product/players.png";
import DiscordImage from "../../Assets/Images/Product/discord.png";
import DiscordImageMobile from "../../Assets/Images/Product/discord-mobile.png";
import FavouritesImage from "../../Assets/Images/Product/favourites.png";
import WalletImage from "../../Assets/Images/Product/wallet.png";
import WalletImageMobile from "../../Assets/Images/Product/wallet-image-mobile.png";

import {useLocation} from "react-router-dom";

const Product = (props) => {
    const {
        isMobile,
    } = props;
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const sectionId = location.hash.substring(1);
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({behavior: "smooth"});
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const sectionData = [
        {
            sectionId: "tournaments",
            isRightImage: true,
            image: TournamentImage,
            title: "Tournaments",
            description: "Competitive gaming for everyone",
            points: `
            <ul>
                <li>Compete in your favourite games and win prizes.</li>
                <br/><li>Create your own tournaments.</li>
                <br/><li>Free & paid tournaments for players of all skill-levels. </li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "education",
            isRightImage: false,
            image: EducationImage,
            title: "Education",
            description: "Learn from the industry’s best",
            points: `
            <ul>
                <li>Pure academy: Exclusive video content from the world's top gamers. Learn strategies, tips and techniques to elevate your game via specialised courses.</li>
                <br/><li>Masterclasses: With professional players hosting educational live presentations, with interactive audience participation.</li>
                <br/><li>1-2-1 coaching: Focused personal coaching completely tailored to your needs from our qualified Pure coaches. </li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "games",
            isRightImage: true,
            image: GamesImage,
            title: "Games",
            description: "The top esports games globally",
            points: `
            <ul>
                <li>The games feature will be the hub for all the tournaments, education, news, matches, video content, teams and players.</li>
                <br/><li>Starting with EA-FC, Counter-Strike 2, League of Legends, Dota 2, Rocket League and Valorant.</li>
                <br/><li>Connect your Steam and Riot accounts to your favourite games.</li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "matches",
            isRightImage: false,
            image: MatchesImage,
            title: "Matches",
            description: "Live match updates & notifications",
            points: `
            <ul>
                <li>Follow your favourite games, teams and players.</li>
                <br/><li>In-depth stats, match highlights, standings & brackets for every match.</li>
                <br/><li>Never miss a thing with personalised notifications.</li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "news",
            isRightImage: true,
            image: NewsImage,
            title: "News",
            description: "Top stories, game, team and player news",
            points: `
            <ul>
                <li>Aggregating all of the latest esports news globally.</li>
                <br/><li>Pure articles with expert opinion and insight. Get comprehensive coverage and in-depth analysis all in one place.</li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "watch",
            isRightImage: false,
            image: WatchImage,
            title: "Watch",
            description: "The ultimate esports viewing experience ",
            points: `
            <ul>
                <li>Live channels from all of your favourite gamers and streamers.</li>
                <br/><li>Popular clips and reels from the biggest esports games tailord for you.</li>
                <br/><li>Live matches & tournaments from the biggest esports competitions, including replays and highlights.</li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "teams",
            isRightImage: true,
            image: TeamsImage,
            title: "Teams",
            description: "A hub for all of the biggest esports teams",
            points: `
            <ul>
                <li>Access the latest news, in-depth stats, player profiles and match details for every team. </li>
                <br/><li>Follow your favourite teams and receive custom notifications personalised for you.</li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "players",
            isRightImage: false,
            image: PlayersImage,
            title: "Players",
            description: "A hub for the biggest esports pro gamers and streamers",
            points: `
            <ul>
                <li>Pro gamers: Accessing all your favourite professionals in the esports with all their content and stats in one place. </li>
                <br/><li>Streamers: Never miss a stream again! Get notified when your favorite streamers go live and catch up on past broadcasts with video-on-demand libraries.</li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "discord",
            isRightImage: true,
            image: isMobile ? DiscordImageMobile : DiscordImage,
            title: "Discord",
            description: "Connecting the gaming community",
            points: `
            <ul>
                <li>Pure will be integrating Discord’s API into the platform allowing users to stay connected.</li>
                <br/><li>Discord will be the chat and messaging system used throughout, whether you’re booking 1-2-1 coaching or messaging friends in a tournament.</li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "favourites",
            isRightImage: false,
            image: FavouritesImage,
            title: "Favourites",
            description: "Hyper-personalisation tailored to you",
            points: `
            <ul>
                <li>Tailored content: Handpick your favourite games, teams, and players to create a custom feed filled with the news, stats, and content you care most about.</li>
                <br/><li>Quick access: Easily access your favourites with a single click from the navigation bar or home screen. No more endless scrolling or searching!</li>
                <br/><li>Enhanced recommendations: Discover new games, teams, and players based on your favourites. Our intelligent algorithm’s will suggest content that you will love.</li>
                <br/>
            </ul>
            `
        },
        {
            sectionId: "wallet",
            isRightImage: true,
            image: isMobile ? WalletImageMobile : WalletImage,
            title: "Wallet",
            description: "Integrated wallet managing fiat & $PURE currency",
            points: `
            <ul>
                <li>Track your balance, transaction history, and token value with ease.</li>
                <br/><li>Win prizes: Participate in contests and tournaments to earn $PURE tokens. Use your winnings to unlock exciting prizes.</li>
                <br/>
            </ul>
            `
        },
    ]


    const renderMobile = () => {
        return (
            <>
                <ProductHeroSection isMobile={isMobile}/>
                {
                    sectionData?.map((section, index) => {
                        return (
                            <section key={index} id={section?.sectionId}
                                     className={`${section?.isRightImage ? "bg-white " : "bg-blue"}`}>
                                <div className={"container py-5"}>
                                    <ImageSection
                                        sectionId={section?.sectionId}
                                        isMobile={isMobile}
                                        isRightImage={section?.isRightImage}
                                        image={section?.image}
                                        title={section?.title}
                                        description={section?.description}
                                        colorDescription={"orange"}
                                        points={section?.points}
                                    />
                                </div>
                            </section>
                        )
                    })
                }
            </>
        )
    };

    const renderLaptop = () => {
        return (
            <>
                <ProductHeroSection isMobile={isMobile}/>
                {
                    sectionData?.map((section, index) => {
                        return (
                            <section id={section?.sectionId} key={index}
                                     className={`height-product-section d-flex align-items-center justify-content-center ${section?.isRightImage ? "bg-white" : "bg-blue"}`}>
                                <div className={"container py-5 h-100 d-flex align-items-center justify-content-center"}>
                                    <ImageSection
                                        isMobile={isMobile}
                                        isRightImage={section?.isRightImage}
                                        image={section?.image}
                                        title={section?.title}
                                        description={section?.description}
                                        colorDescription={"orange"}
                                        points={section?.points}
                                        sectionId={section?.sectionId}
                                    />
                                </div>
                            </section>
                        )
                    })
                }
            </>
        )
    };

    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default Product