import './App.css';
import AppRoutes from "./Routes/AppRoutes";
import './Assets/Styles/sass/fonts.module.scss';
import './Assets/Styles/fonts.css';
import './Assets/Styles/psg.css';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
    return (
        <>
            <AppRoutes/>
        </>
    );
}

export default App;
