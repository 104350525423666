// ProductHeroSection
import React from "react";

import {Card} from "react-bootstrap";
// import {Link} from "react-router-dom";
import HeroBackgroundMobile from "../../Assets/Images/Product/bg-product-hero-mobile.png";
import HeroImage from "../../Assets/Images/Product/product-hero-section.png";
import LogoPureEsports from "../../Assets/Images/Logos/logo-pure-esports.png";

import IconAndroid from "../../Assets/Images/Icons/icon-android.png";
import IconApple from "../../Assets/Images/Icons/icon-apple.png";
import IconWeb from "../../Assets/Images/Icons/icon-web.png";


const ProductHeroSection = (props) => {
    const {
        isMobile,
    } = props;

    const cardStyles = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 0,
        // height: "100%",
    };

    const renderMobile = () => {
        return (
            <>
                <section
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        height: "766px",
                        marginTop:"-100px"
                        // marginTop: !isMobile && "-9%"
                    }}
                >
                    <Card className="border-0 rounded-0 position-relative w-100"
                          style={{height: "100%"}}
                    >

                        <div className={"position-absolute top-0 start-0 w-100 h-100 object-fit-fill"}
                            // style={{height: "529px"}}
                        >
                            <img
                                className={"w-100 h-100"}
                                src={HeroBackgroundMobile}
                                alt="Card background"
                                style={cardStyles}
                            />
                            <div
                                className={"bg-product-hero-section position-absolute bottom-0 start-0 h-100 w-100"}></div>
                        </div>
                        <Card.Body
                            className="d-flex align-items-center justify-content-center position-relative"
                        >
                            <div className={" w-100 "}>
                                <div className={""}>
                                    <div
                                        className={"d-flex flex-column gap-4 align-items-start justify-content-center"}>

                                        <div className={"gilroy fw-400 font-32 text-white"}
                                             style={{lineHeight: "45px"}}
                                        >
                                            The ultimate esports and gaming platform
                                        </div>
                                        <img
                                            src={LogoPureEsports}
                                            alt={"e-sports"}
                                            height={54}
                                            width={153}
                                        />
                                        <div className={"d-flex flex-row align-items-center gap-3"}>
                                            <img
                                                role={"button"}
                                                className={"rounded-circle"}
                                                src={IconAndroid}
                                                alt={"icon"}
                                                height={53}
                                                width={53}
                                            />
                                            <img
                                                role={"button"}
                                                className={"rounded-circle"}
                                                src={IconApple}
                                                alt={"icon"}
                                                height={53}
                                                width={53}
                                            />
                                            <img
                                                role={"button"}
                                                className={"rounded-circle"}
                                                src={IconWeb}
                                                alt={"icon"}
                                                height={53}
                                                width={53}
                                            />
                                        </div>
                                        <div role={"button"}
                                             className={"border-light-gray-2 px-4 py-2 rounded-5 w-100"}>
                                            <div
                                                className={"d-flex align-items-center justify-content-center jost fw-700 font-15 text-white"}>
                                                Coming soon
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={" mt-5"}>
                                    <img
                                        src={HeroImage}
                                        alt={"hero-section"}
                                        className={"img-fluid"}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <section
                    className="d-flex justify-content-center align-items-center product-hero-section"
                >
                    <Card className="border-0 rounded-0 position-relative w-100 product-hero-background py-5 "
                    >

                        {/*<div className="product-hero-background position-absolute top-0 start-0 w-100 h-100">*/}
                        {/*    /!*<div className="bg-product-hero-section position-absolute bottom-0 start-0 h-100 w-100"></div>*!/*/}
                        {/*</div>*/}
                        <Card.Body
                            className="d-flex align-items-center justify-content-center position-relative container py-5"
                        >
                            <div className={"row w-100 h-100"}>
                                <div className={"col-5 m-auto"}>
                                    <div
                                        className={"d-flex flex-column gap-4 align-items-start justify-content-center"}>

                                        <div className={"gilroy fw-400 font-50 text-white"}
                                             style={{lineHeight: "52px"}}
                                        >
                                            The ultimate esports and gaming platform
                                        </div>
                                        <img
                                            src={LogoPureEsports}
                                            alt={"e-sports"}
                                            height={57}
                                            width={162}
                                        />
                                        <div className={"d-flex flex-row align-items-center gap-3"}>
                                            <img
                                                role={"button"}
                                                className={"rounded-circle"}
                                                src={IconAndroid}
                                                alt={"icon"}
                                                height={53}
                                                width={53}
                                            />
                                            <img
                                                role={"button"}
                                                className={"rounded-circle"}
                                                src={IconApple}
                                                alt={"icon"}
                                                height={53}
                                                width={53}
                                            />
                                            <img
                                                role={"button"}
                                                className={"rounded-circle"}
                                                src={IconWeb}
                                                alt={"icon"}
                                                height={53}
                                                width={53}
                                            />
                                        </div>
                                        <div role={"button"}
                                             className={"border-light-gray-2 px-4 py-2 rounded-5"}>
                                            <div
                                                className={"d-flex align-items-center justify-content-center jost fw-700 font-15 text-white"}>
                                                Coming soon
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-7 text-end m-auto"}>
                                    <img
                                        src={HeroImage}
                                        alt={"hero-section"}
                                        className={"product-hero-section-image"}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </>
        )
    };

    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default ProductHeroSection