// ImageSectionFullWidth
import React from "react";
import IconWorldWide from "../../../Assets/Images/Icons/icon-world-wide.png";

import LogoFc from "../../../Assets/Images/ESports/Stats/EA_Sports_FC_logo.png";
import LogoLOL from "../../../Assets/Images/ESports/Stats/League_of_Legends_2019_logo.png";
import LogoDota from "../../../Assets/Images/ESports/Stats/Dota_logo.png";
import LogoCS from "../../../Assets/Images/ESports/Stats/counter-strike-2-logo.png";
import LogoRocketLeague from "../../../Assets/Images/ESports/Stats/Rocket_League_logo.png";
import LogoValorant from "../../../Assets/Images/ESports/Stats/Valorant_logo.png";


const ImageSectionFullWidth = (props) => {
    const {
        isMobile,
        isRight,
        videoUrl,
        title,
        description,
        colorDescription,
        count1,
        countHeading1,
        count2,
        countHeading2,
        count3,
        countHeading3,
        count4,
        countHeading4,
        count5,
        countHeading5,
        count6,
        countHeading6,
        isWordWideIcon,
        isStatsESports,
        globalFootPrint,
    } = props;

    const renderCard = (count, countHeading, isWordWideIcon) => {
        return (
            <>
                <div
                    className={`d-flex flex-column align-items-start px-3 py-2 rounded-4 ${isRight ? "bg-blue" : "bg-white-10"} ${isMobile ? "count-card-mobile":"count-card"}`}>
                    {
                        isWordWideIcon ?
                            <>
                                <img src={IconWorldWide} alt={"icon"} className={`mb-2`} height={59}/>
                                <div className={`gilroy fw-400 text-white border-bottom-${colorDescription}-4 ${isMobile ? "font-32 w-50":"font-50 w-25"}`}></div>
                            </> :

                            <div className={`gilroy fw-400 text-white border-bottom-${colorDescription}-4 ${isMobile ? "font-32 w-50":"font-50 w-25"}`}
                                 style={{lineHeight: "72px"}}>{count}</div>
                    }
                    <div className={`gilroy fw-400 text-white pt-2 ${isMobile ? "font-12":"font-18"}`}
                         style={{lineHeight: isMobile?"14px":"20px"}}>{countHeading}</div>
                </div>
            </>
        )
    }
    const renderMobile = () => {
        return (
            <>
                <section className={`${isRight ? "bg-white" : "bg-blue"} ${isMobile && "pt-3"}`}
                    // style={{height: "615px"}}
                >
                    <div
                        className={`row h-100 d-flex  ${isRight ? "flex-column-reverse col-12 p-0 m-0" : "flex-column-reverse col-12 p-0 m-0"}`}>
                        {
                            !globalFootPrint &&
                            <>
                                <div className={`col-12 ${isRight ?"p-0 m-0": "p-0"}`} style={{height:"430px",}}>
                                    <video
                                        className="h-100 w-100"
                                        style={{
                                            backgroundRepeat: "no-repeat",
                                            objectFit: "fill"
                                        }}
                                        autoPlay={true} loop muted
                                        playsInline={true}
                                        controls={false}
                                    >
                                        <source src={videoUrl} type="video/mp4"/>
                                        {/*Your browser does not support the video tag.*/}
                                    </video>
                                </div>
                            </>
                        }

                        <div className={"col-12"}>
                            <div className={"container d-flex flex-column align-items-start justify-content-center px-4 h-100"}>
                                {
                                    isStatsESports ?
                                        <>
                                            <div className={"row"}>
                                                <div className={"col-4 mb-4 text-center"}>
                                                    <img src={LogoFc} alt={"LogoFc"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 mb-4 text-center"}>
                                                    <img src={LogoLOL} alt={"LogoLol"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 mb-4 text-center"}>
                                                    <img src={LogoDota} alt={"LogoDota"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 text-center"}>
                                                    <img src={LogoCS} alt={"LogoCS"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 text-center"}>
                                                    <img src={LogoRocketLeague} alt={"LogoRocketLeague"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 text-center d-flex align-items-center"}>
                                                    <img src={LogoValorant} alt={"LogoValorant"} className={"img-fluid"}/>
                                                </div>
                                            </div>
                                        </>:
                                        <>

                                            <div className={"d-flex flex-column col-12 text-center"}>
                                                <div
                                                    className={`gilroy fw-400 font-28 ${isRight ? "text-blue" : "text-white"}`}>{title}</div>
                                                <div
                                                    className={`gilroy fw-400 font-14 text-${colorDescription}`}>{description}</div>
                                            </div>
                                        </>
                                }
                                <div className={"d-flex flex-column mt-5 gap-4 col-12"}>
                                    <div className={"row w-100 p-0 m-0"}>
                                        <div className={"col-6 mb-4"}>
                                            {renderCard(count1, countHeading1)}
                                        </div>
                                        <div className={"col-6 mb-4"}>
                                            {renderCard(count2, countHeading2)}
                                        </div>
                                        <div className={"col-6 mb-4"}>
                                            {renderCard(count3, countHeading3)}
                                        </div>
                                        <div className={"col-6 mb-4"}>
                                            {renderCard(count4, countHeading4)}
                                        </div>
                                        <div className={"col-6 mb-4"}>
                                            {renderCard(count5, countHeading5)}
                                        </div>
                                        <div className={"col-6 mb-4"}>
                                            {renderCard(count6, countHeading6, isWordWideIcon)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <section className={`blue-section-container ${isRight ? "bg-white" : "bg-blue"}`}
                    // style={{height: "615px"}}
                >
                    <div
                        className={`row h-100 d-flex  ${isRight ? "flex-row-reverse col-12 p-0 m-0" : "flex-row col-12"}`}>
                        <div className={`col-6 h-100 ${isRight && "p-0"}`}>
                            <video
                                className="h-100 w-100"
                                style={{
                                    backgroundRepeat: "no-repeat",
                                    objectFit: "fill"
                                }}
                                autoPlay={true} loop muted
                                playsInline={true}
                                controls={false}
                            >
                                <source src={videoUrl} type="video/mp4"/>
                                {/*Your browser does not support the video tag.*/}
                            </video>
                        </div>
                        <div className={"col-6"}>
                            <div className={"container d-flex flex-column align-items-start justify-content-center px-4 h-100 count-card-container"}>
                                {
                                    isStatsESports ?
                                        <></>
                                        :
                                        <>

                                            <div className={"d-flex flex-column col-10"}>
                                                <div
                                                    className={`gilroy fw-400 font-50 ${isRight ? "text-blue" : "text-white"}`}>{title}</div>
                                                <div
                                                    className={`gilroy fw-400 font-18 text-${colorDescription}`}>{description}</div>
                                            </div>
                                        </>
                                }
                                <div className={"d-flex flex-column mt-5 gap-4 col-12"}>
                                    <div className={"row w-100"}>
                                        {
                                            isStatsESports &&
                                            <>
                                                <div className={"col-4 mb-4 text-center"}>
                                                    <img src={LogoFc} alt={"LogoFc"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 mb-4 text-center"}>
                                                    <img src={LogoLOL} alt={"LogoLol"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 mb-4 text-center"}>
                                                    <img src={LogoDota} alt={"LogoDota"} className={"img-fluid"}/>
                                                </div>

                                            </>
                                        }
                                        <div className={"col-4 mb-4"}>
                                            {renderCard(count1, countHeading1)}
                                        </div>
                                        <div className={"col-4 mb-4"}>
                                            {renderCard(count2, countHeading2)}
                                        </div>
                                        <div className={"col-4 mb-4"}>
                                            {renderCard(count3, countHeading3)}
                                        </div>
                                        <div className={"col-4 mb-4"}>
                                            {renderCard(count4, countHeading4)}
                                        </div>
                                        <div className={"col-4 mb-4"}>
                                            {renderCard(count5, countHeading5)}
                                        </div>
                                        <div className={"col-4 mb-4"}>
                                            {renderCard(count6, countHeading6, isWordWideIcon)}
                                        </div>
                                        {
                                            isStatsESports &&
                                            <>
                                                <div className={"col-4 mb-4 text-center"}>
                                                    <img src={LogoCS} alt={"LogoCS"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 mb-4 text-center"}>
                                                    <img src={LogoRocketLeague} alt={"LogoRocketLeague"} className={"img-fluid"}/>
                                                </div>
                                                <div className={"col-4 mb-4 text-center d-flex align-items-center"}>
                                                    <img src={LogoValorant} alt={"LogoValorant"} className={"img-fluid"}/>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    };


    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default ImageSectionFullWidth