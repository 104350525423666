// SimpleSlider
import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {
    Autoplay, FreeMode
} from 'swiper/modules';

const SimpleSlider = (props) => {
    const {
        forDiv,
        data,
        renderCardTopPlayer,
        slidesPerView,
        gap
    } = props;


    const renderMain = () => {
        return (
            <>
                <Swiper
                    id={`top${forDiv}-desktop-id`}
                    loop={true}
                    autoplay={true}
                    freeMode={false}
                    slidesPerView={slidesPerView}
                    spaceBetween={gap}
                    // breakpoints={{
                    //     '@0.00': {
                    //         slidesPerView: 3,
                    //         spaceBetween: 20,
                    //     },
                    // }}
                    modules={[FreeMode, Autoplay]}
                    className={`container-xxl`}
                    // style={{height: "388px"}}
                >
                    {data?.map((data, index) => {
                        return (
                            <SwiperSlide
                                key={index + "data"}
                            >
                                {
                                    renderCardTopPlayer(data)
                                }
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </>
        )
    };
    return renderMain();
};

export default SimpleSlider
