// ContactUs
import React from "react";
import ContactUsHeroSection from "../../Components/ContactUsComponents/ContactUsHeroSection";
// import IconAboutUs from "../../Assets/Images/Contactus/icon-about-us.png";
// import IconProductEnquire from "../../Assets/Images/Contactus/icon-product-enquire.png";
// import IconEsportEnquire from "../../Assets/Images/Contactus/icon-esports-enquire.png";
// import IconSportEnquire from "../../Assets/Images/Contactus/icon-sport-enquire.png";
// import IconInvestingEnquire from "../../Assets/Images/Contactus/icon-investing-enquire.png";
// import {Col, Row} from "react-bootstrap";
import {newsData} from "../../Configs/NewsData";
import NewsCard from "../../Components/ReUseAbleComponents/CardsComponents/NewsCard";

const ContactUs = (props) => {
    const {
        isMobile,
    } = props;


    // const renderIcon = (icon, description, width, height) => {
    //     return (
    //         <div className={"d-flex flex-column gap-3 align-items-center"}>
    //             <img
    //                 src={icon}
    //                 alt={`icon-${description}`}
    //                 // className={"img-fluid"}
    //                 height={height}
    //                 width={width}
    //             />
    //             <div className={`text-center gilroy fw-400 text-blue ${isMobile ? "font-14" : "font-16"}`}>{description}</div>
    //         </div>
    //     )
    // }
    const renderMobile = () => {
        return (
            <>
                <ContactUsHeroSection isMobile={isMobile}/>
                {/*<section className={"container py-5 w-75"}>*/}
                {/*    <Row className={"bg-"}>*/}
                {/*        <Col>*/}
                {/*            {renderIcon(IconAboutUs, "Enquiries About us", "74px", "47px")}*/}
                {/*        </Col>*/}
                {/*        <Col>*/}
                {/*            {renderIcon(IconProductEnquire, "Product Enquiries", "50px", "49.17px", )}*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row className={"py-5"}>*/}
                {/*        <Col>*/}
                {/*            {renderIcon(IconEsportEnquire, "Esports Enquiries", "59px","48px")}*/}
                {/*        </Col>*/}
                {/*        <Col>*/}
                {/*            {renderIcon(IconSportEnquire, "Sport Enquiries", "54px","53px")}*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row>*/}
                {/*        {renderIcon(IconInvestingEnquire, "Investing Enquiries", "60px", "52px")}*/}
                {/*    </Row>*/}
                {/*</section>*/}
                <section className={`bg-white py-4`}
                    // style={{height: "812px"}}
                >
                    <div className={"d-flex flex-column align-items-center justify-content-center "}>
                        <div className={"gilroy fw-400 font-28 text-blue"}>Latest news</div>
                        <div className={"w-75 text-center gilroy-light fw-400 font-14 text-blue opacity my-2"}>Here’s the latest updates in the world of Pure Sport Group</div>
                    </div>
                    <div className={"mb-3 px-3 pb-2 w-100 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                        {
                            newsData?.map((news, index) => {
                                return (
                                    <NewsCard
                                        key={index}
                                        isMobile={isMobile}
                                        background={"bg-white"}
                                        index={index}
                                        width={"90%"}
                                        imageHeight={213}
                                        image={news?.image}
                                        title={news?.title}
                                        description={news?.description}
                                        newsLink={news?.id}
                                        externalLink={news?.externalLink}
                                        link={news?.link}
                                    />
                                )
                            })
                        }
                    </div>
                    {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                    {/*    {renderLearnMoreButton()}*/}
                    {/*</div>*/}
                </section>
            </>
        )
    };

    const renderLaptop = () => {
        return (
            <>
                <ContactUsHeroSection isMobile={isMobile}/>
                {/*<section className={"container my-5"}>*/}
                {/*    <div className={"d-flex align-items-center justify-content-evenly"}>*/}
                {/*        {renderIcon(IconAboutUs, "Enquiries About us", "113px", "71px")}*/}
                {/*        {renderIcon(IconProductEnquire, "Product Enquiries", "72px","71px")}*/}
                {/*        {renderIcon(IconEsportEnquire, "Esports Enquiries", "91px","73px")}*/}
                {/*        {renderIcon(IconSportEnquire, "Sport Enquiries", "72px", "71px")}*/}
                {/*        {renderIcon(IconInvestingEnquire, "Investing Enquiries","83px","71px")}*/}
                {/*    </div>*/}
                {/*</section>*/}
                <section className={`bg-white`}
                    // style={{height: "694px"}}
                >
                    <div className={"container pb-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-blue"}>Latest news</div>
                            <div className={"gilroy-light fw-400 font-18 text-blue opacity my-2"}>
                                Here’s the latest updates in the world of Pure Sport Group
                            </div>
                        </div>
                        <div className={"row mt-3 p-0 py-3"}>
                            {
                                newsData?.map((news, index) => {
                                    return (
                                        <div key={index}
                                             className={"col-4 mb-4"}>
                                            <NewsCard
                                                key={index}
                                                isMobile={isMobile}
                                                background={"bg-white"}
                                                index={index}
                                                width={"100%"}
                                                imageHeight={213}
                                                image={news?.image}
                                                title={news?.title}
                                                description={news?.description}
                                                newsLink={news?.id}
                                                externalLink={news?.externalLink}
                                                link={news?.link}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                        {/*    {renderLearnMoreButton()}*/}
                        {/*</div>*/}
                    </div>
                </section>
            </>
        )
    };
    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default ContactUs